import React, { useCallback, useEffect, useState } from 'react';
import PricingPlanCard from "../../components/PricingPlan/PricingPlanCard";
import { useDispatch } from 'react-redux';
import * as authDucks from '../../ducks/auth';
import * as pricingPlanDucks from '../../ducks/pricingPlan';
import { useSelector } from 'react-redux';
import { toastify } from '../../ducks/toast';
import './PricingPlan.css';

const PricingPlan = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    profile: {},
    newPlanList: [],
    basicPlan: {},
    monthPlan: [],
    yearPlan: [],
    lstAddon: [],
    isOpenChargebeeFrm: false,
    isOpenChargebeeAddonFrm: false,
    planBilling: true,
    plv: 'month',
    proPlanList: [],
    proOptionList: [],
    growthPlanList: [],
    growthOptionList: [],
    basicPlanPrice: '',
    basicPlanVisitor: '',
    selctdProPlan: {},
    selctdProPlanPrice: '',
    selctdProPlanVisitor: '',
    selctdGrowthPlan: {},
    selctdGrowthPlanPrice: '',
    selctdGrowthPlanVisitor: '',
    selctdPlanId: '',
    selctdPlanPrice: 0,
    isFromURL: false,
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const profileState = useSelector(state => state.auth.profile);
  const lstPlan = useSelector(state => state.pricingPlan.lstPlan);
  const lstAddon = useSelector(state => state.pricingPlan.lstAddon);
  const chargebeeFrm = useSelector(state => state.pricingPlan.chargebeeFrm);
  const chargbeeSuccess = useSelector(state => state.pricingPlan.chargbeeSuccess);
  const chargebeeAddonFrm = useSelector(state => state.pricingPlan.chargebeeAddonFrm);
  const isLoading = useSelector(state => state.loading.isLoading);

  //api call.
  useEffect(() => {
    dispatch(pricingPlanDucks.getPlan());
    dispatch(pricingPlanDucks.getAddon());
  }, []);

  useEffect(() => {
    //set profileState data.
    if (profileState) {
      changeNameValue({ profile: profileState });
    }
    //set lstAddon data.
    if (lstAddon) {
      changeNameValue({ lstAddon });
    }
    //set plv data.
    if (profileState && profileState?.billing?.pdru) {
      changeNameValue({ plv: profileState?.billing?.pdru })
    }
    //for free user set year plan.
    if (profileState?.csub === 'free' || profileState?.csub === undefined) {
      changeNameValue({ plv: 'year' });
    }
  }, [profileState, lstAddon]);

  const subscribePlanFromURL = (id, planAry, userData) => {
    if (state.isFromURL === false) {
      state.isFromURL = true;
      let _index = planAry.findIndex(x => x.plan.id === id);
      if (_index !== -1) {
        let obj = {
          plan_id: id,
          email: userData.e,
          first_name: userData.fn
        };
        if (userData.cbe && userData.cbe.client_id) {
          obj.cust_id = userData.cbe.client_id;
        }
        if (userData.billing && userData.billing.sbid) {
          obj.sub_id = userData.billing.sbid;
        }
        dispatch(pricingPlanDucks.createChargeBeeForm(obj));
      }
      else {
        dispatch(toastify({ type: 'error', msg: "Plan Not Found!" }));
      }
    }
  }

  useEffect(() => {
    //set plan data.
    if (lstPlan && state.profile && localStorage.getItem('pvd') !== 'wix') {
      const updatedMonthPlan = [];
      const updatedYearPlan = [];
      const updatedNewPlanList = [];

      lstPlan.forEach(planObj => {
        // Create a shallow copy of the plan object
        let planCopy = { ...planObj.plan, newPrice: planObj.plan.price / 100 };

        if (planCopy.period_unit === 'month' && planCopy.period === 1 && planCopy.id !== 'free_plan') {
          updatedMonthPlan.push(planCopy);
        } else if (planCopy.period_unit === 'year' && planCopy.period === 1 && planCopy.id !== 'free_plan') {
          updatedYearPlan.push(planCopy);
        }

        if ((planCopy.period_unit === 'year' || planCopy.period_unit === 'month') && planCopy.period === 1 && planCopy.id !== 'free_plan' && planCopy.id !== 'basic-yearly') {
          updatedNewPlanList.push(planCopy);
        }

        if (planCopy.id === 'basic-yearly' && state.profile.csub === 'free') {
          updatedNewPlanList.push(planCopy);
        }

        if (planCopy.id === 'basic-yearly') {
          changeNameValue({ basicPlan: planCopy })
        }
      });

      let obj = {
        monthPlan: updatedMonthPlan,
        yearPlan: updatedYearPlan,
        newPlanList: updatedNewPlanList,
        selctdPlanId: state.profile?.billing?.pobj?.id,
        selctdPlanPrice: (state.profile.billing && state.profile.billing?.icnc === false) && (state.profile?.billing?.pobj?.price) / 100
      }
      changeNameValue(obj);
    }
    let params = new URLSearchParams(window?.location.search);
    const params_planId = params.get('plan');
    if (lstPlan && state.profile && params && params_planId) {
      if (params_planId) {
        state.isOpenChargebeeFrm = true;
        subscribePlanFromURL(params_planId, lstPlan, state.profile);
      }
    }

    //chargebee form open for purchase plan.
    if (chargebeeFrm && state.isOpenChargebeeFrm === true) {
      let isSuccess = false;
      if (chargebeeFrm) {
        // changeNameValue({ isOpenChargebeeFrm: true });

        var chargebeeInstance = window.Chargebee.getInstance();
        chargebeeInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeeFrm;
              resolve(sampleResponse);
            });
          },
          success: function (hostedPageId) {
            //clear data chargebee form
            dispatch(pricingPlanDucks.createChargeBeeFormClear());
            isSuccess = true;
            let obj = { pgId: hostedPageId };
            setTimeout(() => {
              dispatch(pricingPlanDucks.chargbeePayment(obj));
            }, 100);
          },
          close: function () {
            setTimeout(() => {
              //clear data chargebee form
              dispatch(pricingPlanDucks.createChargeBeeFormClear());
              changeNameValue({ isOpenChargebeeFrm: false });
              if (isSuccess === true) {
                if (typeof window !== 'undefined' && window?.location) {
                  window.location.href = '/pixel';
                }
              }
              else {
                dispatch(authDucks.getProfile());
              }
            }, 100);
          }
        });
      }
    }
    if (chargbeeSuccess && state.profile && state.profile.billing) {
      if (chargbeeSuccess.content && chargbeeSuccess.content.invoice) {
        let paidAmount = chargbeeSuccess.content.invoice.amount_paid / 100;
        paidAmount = paidAmount * 74;
        if (window.gtag) {
          try {
            //for google ads
            window.gtag('event', 'conversion', {
              'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC',
              'value': paidAmount,
              'currency': 'INR',
              'transaction_id': state.profile.ti
            });
          } catch (error) {
            console.log('error===>', error);
          }
        }
      }
    }

    //chargebee Checkout form for addon.
    if (chargebeeAddonFrm && state.isOpenChargebeeAddonFrm === true) {
      let isSuccess = false;
      if (chargebeeAddonFrm) {
        // changeNameValue({ isOpenChargebeeAddonFrm: true })

        var chargebeeInstanceAddon = window.Chargebee.getInstance();
        chargebeeInstanceAddon.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeeAddonFrm;
              resolve(sampleResponse);
            });
          },
          success: function (hostedPageId) {
            isSuccess = true;
            //clear data chargebee form
            dispatch(pricingPlanDucks.createChargeBeeAddonFormClear());
            let obj = { pgId: hostedPageId, addon: true };
            setTimeout(() => {
              dispatch(pricingPlanDucks.chargbeeAddonPayment(obj));
            }, 100);
          },
          close: function () {
            setTimeout(() => {
              changeNameValue({ isOpenChargebeeAddonFrm: false });
              //clear data chargebee form
              dispatch(pricingPlanDucks.createChargeBeeAddonFormClear());
              if (isSuccess === true) {
                if (typeof window !== 'undefined' && window?.location) {
                  window.location.href = '/pixel';
                }
              }
              else {
                dispatch(authDucks.getProfile());
              }
            }, 100);
          }
        });
      }
    }

  }, [lstPlan, chargebeeFrm, state.isOpenChargebeeFrm, chargbeeSuccess, chargebeeAddonFrm, state.isOpenChargebeeAddonFrm, state.profile]);

  //handle Tab Change.
  const handleTabChange = (data) => {
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Plan Toggle Dashboard', data.tabName, state.profile?.e || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Plan Toggle Dashboard', { 'event_action': data.tabName, 'event_label': state.profile?.e || '' });
      } catch (error) { console.log(error); }
    }

    changeNameValue({ plv: data.tabName });
    // localStorage.setItem('plv', data.tabName);

    // Update plan options for both 'month' and 'year' tabs
    updatePlanOptions(state.proPlanList, 'proOptionList', 'selctdProPlan', 'selctdProPlanVisitor', 'selctdProPlanPrice', true);
    updatePlanOptions(state.growthPlanList, 'growthOptionList', 'selctdGrowthPlan', 'selctdGrowthPlanVisitor', 'selctdGrowthPlanPrice', true);
  }

  // function convert visitors.
  const convert = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  };

  // function update Month PlanList.
  const updateMonthPlanList = (excludedIds, stateKey) => {
    const filteredPlans = state.monthPlan.filter(({ id }) => excludedIds.includes(id))
    changeNameValue({ [stateKey]: filteredPlans });
  };

  // function update Year PlanList.
  const updateYearPlanList = (excludedIds, stateKey) => {
    const filteredPlans = state.yearPlan.filter(({ id }) => excludedIds.includes(id));
    changeNameValue({ [stateKey]: filteredPlans });
  };
  //set year and month plan list data.
  useEffect(() => {
    if (state.plv === 'year') {
      if (state.yearPlan && state.yearPlan.length > 0) {
        updateYearPlanList(['startups-yearly-plan', 'standard-yearly-plan', 'premium-yearly-plan'], 'proPlanList');
        updateYearPlanList(['agency-starter-yearly', 'agency-standard-yearly', '-agency-premium-yearly', 'agency-enterprise-yearly', '1-million-visitors'], 'growthPlanList');
      }
    } else {
      if (state.monthPlan && state.monthPlan.length > 0) {
        updateMonthPlanList(['startups-monthly-plan', 'standard-monthly-plan', 'premium-monthly-plan'], 'proPlanList');
        updateMonthPlanList(['agency-starter-monthly', 'agency-standard-monthly', 'agency-premium-monthly', 'agency-enterprise-monthly', '1-million-visitors-month'], 'growthPlanList');
      }
    }
    if (state.basicPlan) {
      let obj = {
        basicPlanPrice: (state.basicPlan.price / 100),
        basicPlanVisitor: convert(state.basicPlan.cf_plan_quota)
      }
      changeNameValue(obj);
    }
  }, [state.plv, state.monthPlan, state.yearPlan, state.basicPlan]);

  // function updatePlanOptions
  const updatePlanOptions = (planList, optionListKey, selctdPlanKey, selctdPlanVisitorKey, selctdPlanPriceKey, resetSelection = false) => {
    if (planList) {
      planList.sort((a, b) => a.cf_plan_quota - b.cf_plan_quota);

      const options = planList.map((val) => ({
        label: `${convert(val.cf_plan_quota)} Visitors/month`,
        value: val.cf_plan_quota,
      }));
      changeNameValue({ [optionListKey]: options });
    }

    // Filter plan based on selctdPlanId
    // const filterPlan = planList.filter((val) => val.id === state.selctdPlanId);

    // const selctdPlan = state[selctdPlanVisitorKey];
    // if (!selctdPlan) {
    //   let defaultPlan;
    //   if (filterPlan.length > 0) {
    //     defaultPlan = filterPlan[0];
    //   } else {
    //     defaultPlan = planList[0]; // First plan in the list
    //   }
    //   changeNameValue({
    //     [selctdPlanVisitorKey]: defaultPlan?.cf_plan_quota,
    //     [selctdPlanPriceKey]: defaultPlan?.price / 100,
    //   });
    // } else {
    //   const filteredPlan = planList.find(item => item.cf_plan_quota === selctdPlan);
    //   if (filteredPlan) {
    //     changeNameValue({ [selctdPlanPriceKey]: filteredPlan.price / 100 });
    //     changeNameValue({ [selctdPlanKey]: filteredPlan });
    //   }
    // }

    const filterPlan = planList.filter((val) => val.id === state.selctdPlanId);
    const filteredPlanIndex = planList.findIndex((val) => val.id === state.selctdPlanId);
    let nextPlan = null;

    // Check if a next plan exists
    if (filteredPlanIndex !== -1 && filteredPlanIndex < planList.length - 1) {
      nextPlan = planList[filteredPlanIndex + 1];
    }

    const selctdPlan = state[selctdPlanVisitorKey];
    if (!selctdPlan) {
      let defaultPlan;
      if (nextPlan && (state.profile.billing && state.profile.billing?.icnc === false)) {
        defaultPlan = nextPlan;
      } else {
        defaultPlan = planList[0]; // First plan in the list
      }
      changeNameValue({
        [selctdPlanVisitorKey]: defaultPlan?.cf_plan_quota,
        [selctdPlanPriceKey]: defaultPlan?.price / 100,
      });
    } else {
      const filteredPlan = planList.find(item => item.cf_plan_quota === selctdPlan);
      if (filteredPlan) {
        changeNameValue({ [selctdPlanPriceKey]: filteredPlan.price / 100 });
        changeNameValue({ [selctdPlanKey]: filteredPlan });
      }
    }

    // If resetSelection is true, always select the first plan
    if (resetSelection) {
      const defaultPlan = planList[0];
      changeNameValue({
        [selctdPlanVisitorKey]: defaultPlan?.cf_plan_quota,
        [selctdPlanPriceKey]: defaultPlan?.price / 100,
        [selctdPlanKey]: defaultPlan,
      });
      return;
    }
  };

  // useEffect to update plan options on state change
  useEffect(() => {
    updatePlanOptions(state.proPlanList, 'proOptionList', 'selctdProPlan', 'selctdProPlanVisitor', 'selctdProPlanPrice');
    updatePlanOptions(state.growthPlanList, 'growthOptionList', 'selctdGrowthPlan', 'selctdGrowthPlanVisitor', 'selctdGrowthPlanPrice');
  }, [state.proPlanList, state.growthPlanList, state.selctdProPlanVisitor, state.selctdGrowthPlanVisitor, state.selctdPlanId, state.profile]);

  //get Upgrade Confirmation.
  const getUpgradeConfirmation = (type) => {
    const planList = {
      pro: state.selctdProPlan,
      growth: state.selctdGrowthPlan,
      basic: state.basicPlan
    };

    const selectedPlan = planList[type];

    if (selectedPlan) {
      let _price = 0;
      const isValid = ((selectedPlan.price / 100) > _price)
      if (isValid === false) {
        return;
      }
      if (window.ga) {
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', 'Plan Select Dashboard', selectedPlan.invoice_name, state.profile.e, { transport: 'beacon' });
          //for GA-4
          window.gtag('event', 'Plan Select Dashboard', { 'event_action': selectedPlan.invoice_name, 'event_label': state.profile ? state.profile.e : '' });
        } catch (error) {
          console.log(error);
        }
      }
      state.coupon = state.cn;
      changeNameValue({ isOpenChargebeeFrm: true });

      let _coupon = [];
      if (state.profile.rfid && state.profile.rfid !== 'null') {
        _coupon.push('REFERRAL10');
      }
      let obj = {
        plan_id: selectedPlan?.id,
        sub_id: state.profile?.billing?.sobj?.id,
        email: state.profile.e,
        first_name: state.profile.fn,
        coupon: _coupon
      };
      if (state.profile.cbe && state.profile.cbe.client_id) {
        obj.cust_id = state.profile.cbe.client_id;
      }

      if (window.gtag) {
        try {
          window.gtag('event', 'view_item', {
            value: selectedPlan.newPrice,//plan amount: 16,26..
            pvd: state.profile.pvd,
            event_label: selectedPlan.name,//plan name
            email: state.profile.e,
            path: window?.location.pathname,//page url
            items: [
              {
                item_id: selectedPlan.name,//plan name
                item_name: selectedPlan.name,//plan name,
                price: selectedPlan.newPrice,//plan amount: 16,26..
                quantity: 1,
                currency: 'USD'
              }
            ]
          });
        } catch (error) {
          console.log(error);
        }
      }
      dispatch(pricingPlanDucks.createChargeBeeForm(obj));
    }
  }

  //handle PurchaseChargebee Addon.
  const handlePurchaseChargebeeAddon = (addonId) => {
    let _coupon = [];
    let obj = {
      addon_id: addonId,
      email: state.profile.e,
      first_name: state.profile.fn,
      coupon: _coupon
    };
    if (state.profile.cbe && state.profile.cbe.client_id) {
      obj.cust_id = state.profile.cbe.client_id;
    }
    if (state.profile.billing && state.profile.billing.sbid) {
      obj.sub_id = state.profile.billing.sbid;
    }
    changeNameValue({ isOpenChargebeeAddonFrm: true });
    dispatch(pricingPlanDucks.createChargeBeeAddonForm(obj));

    try {
      let tracker = window.ga.getAll()[0];
      tracker.send('event', 'Product Button Click', 'PricingPage_AddOn', state?.profile?.e || '', { transport: 'beacon' });
      //for GA-4
      window.gtag('event', 'Product Button Click', { 'event_action': 'PricingPage_AddOn', 'event_label': state?.profile?.e || '' });
    } catch (error) {
      console.log(error);
    }
  }

  //formmet number.
  const formmetNumber = (nu) => {
    return nu.toLocaleString();
  }

  const handleChatButton = () => {
    if (window.Froged) {
      window.Froged('message', 'I have more than 200K monthly visitors');
    }
  }

  //handleStartTrial.
  const handleStart = () => {
    let obj = {
      userTi: localStorage.getItem('ti'),
    }
    dispatch(pricingPlanDucks.activeTrialPlan(obj));
  }

  return (
    <PricingPlanCard
      state={state}
      planBilling={state.planBilling}
      isLoading={isLoading}
      changeNameValue={changeNameValue}
      handleTabChange={handleTabChange}
      getUpgradeConfirmation={getUpgradeConfirmation}
      handlePurchaseChargebeeAddon={handlePurchaseChargebeeAddon}
      formmetNumber={formmetNumber}
      handleChatButton={handleChatButton}
      handleStart={handleStart}//handleStartTrial
    />
  );
};

export default PricingPlan;
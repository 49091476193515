import React, { useCallback, useEffect, useState } from 'react';
import WixPricingPlanCard from "../../components/WixPricingPlan/WixPricingPlanCard";
import { useDispatch } from 'react-redux';
import * as pricingPlanDucks from '../../ducks/pricingPlan';
import * as wixPlanDucks from '../../ducks/wixPricingPlan'
import { useSelector } from 'react-redux';

const WixPricingPlan = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    profile: {},
    plv: 'month',
    proPlanList: [],
    proOptionList: [],
    growthPlanList: [],
    growthOptionList: [],
    selctdProPlanPrice: '',
    selctdProPlanVisitor: '',
    selctdGrowthPlanPrice: '',
    selctdGrowthPlanVisitor: '',
    selctdPlanId: '',
    wixOpen: false,
    plvChange: false
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //api data.
  const profileState = useSelector(state => state.auth.profile);
  const lstPlan = useSelector(state => state.pricingPlan.lstPlan);


  //api call.
  useEffect(() => {
    dispatch(pricingPlanDucks.getPlan());
  }, []);

  useEffect(() => {
    //set profileState data.
    let prdu;
    if (profileState && profileState.billing && profileState.billing.icnc !== true) {
      if (profileState?.csub === 'free') {
        prdu = profileState?.billing?.pobj?.period_unit.toLowerCase()
      } else {
        prdu = profileState?.billing?.pobj?.prdu;
      }

      const prduLowercase = prdu ? prdu.toString().toLowerCase() : '';
      changeNameValue({ profile: profileState, selctdPlanId: profileState.billing.pobj._id, selctdPlanPrice: profileState?.billing?.pobj?.prc, plv: prduLowercase });
    }

    if (lstPlan) {
      const growthPlans = lstPlan.filter(plan => plan.grp === 'growth');

      const proPlans = lstPlan.filter(plan => plan.grp === 'pro');
      changeNameValue({ growthPlanList: growthPlans, proPlanList: proPlans });
      changeNameValue({ plvChange: true })
    }
  }, [profileState, lstPlan]);

  const handleTabChange = (data) => {
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Plan Toggle Dashboard', data.tabName, state.profile?.e || '', { transport: 'beacon' });

        window.gtag('event', 'Plan Toggle Dashboard', { 'event_action': data.tabName, 'event_label': state.profile?.e || '' });
      } catch (error) { console.log(error); }
    }
    changeNameValue({ plv: data.tabName });
  }

  // function convert visitors.
  const convert = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  };

  // function updatePlanOptions
  const updatePlanOptions = (planList, optionListKey) => {
    if (planList) {
      planList.sort((a, b) => a.uaq - b.uaq);
      const options = planList.map((val) => ({
        label: `${convert(val.uaq)} Visitors/month`,
        value: val.uaq,
      }));
      changeNameValue({ [optionListKey]: options });
    }
  };

  const handleCloseModal = () => {
    changeNameValue({ wixOpen: false });
  }

  // useEffect to update plan options on state change
  useEffect(() => {
    if (state.plv === 'month') {
      const proMonthOption = state.proPlanList.filter((elm) => elm.prdu === 'Month');

      const growthMonthOption = state.growthPlanList.filter((elm) => elm.prdu === 'Month');

      updatePlanOptions(proMonthOption, 'proOptionList');
      updatePlanOptions(growthMonthOption, 'growthOptionList');

      let prcPro = proMonthOption.length > 0 && proMonthOption.sort((a, b) => a.prc - b.prc);
      let prcGrowth = growthMonthOption.length > 0 && growthMonthOption.sort((a, b) => a.prc - b.prc);

      changeNameValue({ selctdProPlanPrice: prcPro[0]?.prc, selctdGrowthPlanPrice: prcGrowth[0]?.prc, selctdProPlanVisitor: prcPro[0]?.uaq, selctdGrowthPlanVisitor: prcGrowth[0]?.uaq });
    }
    else {
      const proYearOption = state.proPlanList.filter((elm) => elm.prdu === 'Year');
      const growthYearOption = state.growthPlanList.filter((elm) => elm.prdu === 'Year');

      updatePlanOptions(proYearOption, 'proOptionList');
      updatePlanOptions(growthYearOption, 'growthOptionList');

      let prcPro = proYearOption.length > 0 && proYearOption.sort((a, b) => a.prc - b.prc);
      let prcGrowth = growthYearOption.length > 0 && growthYearOption.sort((a, b) => a.prc - b.prc);

      changeNameValue({ selctdProPlanPrice: prcPro[0]?.prc, selctdGrowthPlanPrice: prcGrowth[0]?.prc, selctdProPlanVisitor: prcPro[0]?.uaq, selctdGrowthPlanVisitor: prcGrowth[0]?.uaq });
    }
  }, [state.proPlanList, state.growthPlanList, state.plv]);

  useEffect(() => {
    if (lstPlan) {
      let sortLstPlan = lstPlan?.length > 0 && lstPlan.filter((elm) => elm.prc !== 0).sort((elmA, elmB) => elmA.prc - elmB.prc);

      let index = sortLstPlan?.length > 0 && sortLstPlan.findIndex(item => item.prc === profileState?.billing?.pobj?.prc);

      if ((index + 1) < sortLstPlan?.length) {

        let nextPlan = sortLstPlan[index + 1];

        if (nextPlan.prdu.toLowerCase() === 'Year') {
          changeNameValue({ selctdGrowthPlanVisitor: parseInt(nextPlan.uaq), selctdGrowthPlanPrice: nextPlan.prc })
        }
        else {
          changeNameValue({ selctdProPlanVisitor: parseInt(nextPlan.uaq), selctdProPlanPrice: nextPlan.prc })
        }
      }
      else {
        if (profileState?.billing?.pobj?.prdu.toLowerCase() === 'Year') {
          changeNameValue({ selctdGrowthPlanVisitor: parseInt(profileState?.billing?.pobj?.uaq), selctdGrowthPlanPrice: profileState?.billing?.pobj?.prc })
        }
        else {
          changeNameValue({ selctdProPlanVisitor: parseInt(profileState?.billing?.pobj?.uaq), selctdProPlanPrice: profileState?.billing?.pobj?.prc })
        }
      }
    }
  }, [state.plvChange])



  // change visitior
  useEffect(() => {
    if (state.plv === 'month') {
      const proMonthOption = state.proPlanList.filter((elm) => elm.prdu === 'Month');
      const growthMonthOption = state.growthPlanList.filter((elm) => elm.prdu === 'Month');

      if (state.selctdProPlanVisitor !== '') {
        let selctdProPlanPrice = proMonthOption.find((elm) => elm.uaq === state.selctdProPlanVisitor);
        changeNameValue({ selctdProPlanPrice: selctdProPlanPrice?.prc })
      }

      if (state.selctdGrowthPlanVisitor !== '') {
        let selctdGrowthPlanPrice = growthMonthOption.find((elm) => elm.uaq === state.selctdGrowthPlanVisitor);
        changeNameValue({ selctdGrowthPlanPrice: selctdGrowthPlanPrice?.prc })
      }
    }
    else {
      const proYearOption = state.proPlanList.filter((elm) => elm.prdu === 'Year');
      const growthYearOption = state.growthPlanList.filter((elm) => elm.prdu === 'Year');

      if (state.selctdProPlanVisitor !== '') {
        let selctdProPlanPrice = proYearOption.find((elm) => elm.uaq === state.selctdProPlanVisitor);
        changeNameValue({ selctdProPlanPrice: selctdProPlanPrice?.prc })
      }

      if (state.selctdGrowthPlanVisitor !== '') {
        let selctdGrowthPlanPrice = growthYearOption.find((elm) => elm.uaq === state.selctdGrowthPlanVisitor);
        changeNameValue({ selctdGrowthPlanPrice: selctdGrowthPlanPrice?.prc })
      }
    }
  }, [state.selctdProPlanVisitor, state.selctdGrowthPlanVisitor])

  const handleWixPayment = (val, open) => {
    let planList;
    let planDetail;
    changeNameValue({ wixOpen: true })
    if (open === 'month') {
      if (state.proPlanList.some((elm) => elm.uaq === val)) {
        planDetail = state.proPlanList.filter((elm) => elm.uaq === val);
      } else if (state.growthPlanList.some((elm) => elm.uaq === val)) {
        planDetail = state.growthPlanList.filter((elm) => elm.uaq === val);
      } else {
        console.error("Plan not found in either list");
        return;
      }

      planList = planDetail.find((elm) => elm.prdu.toLowerCase() == open)

    } else if (open === 'year') {
      if (state.proPlanList.some((elm) => elm.uaq === val)) {
        planDetail = state.proPlanList.filter((elm) => elm.uaq === val);
      } else if (state.growthPlanList.some((elm) => elm.uaq === val)) {
        planDetail = state.growthPlanList.filter((elm) => elm.uaq === val);
      } else {
        console.error("Plan not found in either list");
        return;
      }
      planList = planDetail.find((elm) => elm.prdu.toLowerCase() == open)
    }
    dispatch(wixPlanDucks.goToWixCheckOut({ planID: planList?.wpid }));
  }

  return (
    <WixPricingPlanCard
      state={state}
      changeNameValue={changeNameValue}
      handleTabChange={handleTabChange}
      handleWixPayment={handleWixPayment}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default WixPricingPlan;
import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { toastify } from './ducks/toast';
import { useDispatch } from 'react-redux';
import * as authDucks from './ducks/auth';
import { isWhitelable } from './services/permissions';

let authToken = localStorage.getItem('authToken');
let _isWhiteLabel = isWhitelable();

const LazyDashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const LazyLogin = lazy(() => import('./containers/Login/Login'));
const LazySignUp = lazy(() => import('./containers/SignUp/SignUp'));
const LazyResetPwd = lazy(() => import('./containers/ResetPwd/ResetPwd'));
const LazyWhitelabelLogin = lazy(() => import('./containers/WhitelabelLogin/WhitelabelLogin'));
const LazyForgotPwd = lazy(() => import('./containers/ForgotPwd/ForgotPwd'));
const LazyWhitelabelForgotPwd = lazy(() => import('./containers/WhitelabelForgotPwd/WhitelabelForgotPwd'));
const LazyUsers = lazy(() => import('./containers/Users/Users'));
const LazyGoal = lazy(() => import('./containers/Goal/Goal'));
const LazyVisitor = lazy(() => import('./containers/Visitor/Visitor'));
const LazySetting = lazy(() => import('./containers/Setting/Setting'));
const LazyNotifShopify = lazy(() => import('./containers/NotifShopify/NotifShopify'));
const LazyNotifEdit = lazy(() => import('./containers/NotifEdit/NotifEdit'));
const LazyNotification = lazy(() => import('./containers/Notif/Notif'));
const LazyDataPage = lazy(() => import('./containers/DataPage/DataPage'));
const LazyAnalytics = lazy(() => import('./containers/Analytics/Analytics'));
const LazyPixel = lazy(() => import('./containers/Pixel/Pixel'));
const LazyManageAccount = lazy(() => import('./containers/ManageAccount/ManageAccount'));
const LazyReferEarn = lazy(() => import('./containers/ReferEarn/ReferEarn'));
const LazyIntegration = lazy(() => import('./containers/Integration/Integration'));
const LazyPricingPlan = lazy(() => import('./containers/PricingPlan/PricingPlan'));
const LazyOnBoard = lazy(() => import('./containers/OnBoard/OnBoard'));
const LazyShopifyPricingPlan = lazy(() => import('./containers/ShopifyPricingPlan/ShopifyPricingPlan'));
const LazyWixPricingPlan = lazy(() => import('./containers/WixPricingPlan/WixPricingPlan'));
const LazyPaymentInvoice = lazy(() => import('./containers/BillingInvoice/BillingInvoice'));
const LazyReport = lazy(() => import('./containers/Report/Report'));


//get value from querystring
let params = new URLSearchParams(window.location.search);
const params_jwt = params.get('q');
const params_un = params.get('un');
const params_isbusr = params.get('isbusr');
const params_csub = params.get('csub');
const params_typInt = params.get('typint');
const params_typIntMsg = params.get('typintmsg');
const params_event = params.get('event');
const params_pt = params.get('pt');
const params_rp = params.get('rp');
const params_email = params.get('n');
const params_regTyp = params.get('typ');
const params_instance = params.get('instance');
const params_instanceId = params.get('instanceId');
const params_tgid = params.get('tgid');
const params_tgtkn = params.get('tgtkn');
const params_bginstance = params.get('bginstance');
const params_storeId = params.get('store_id');
const params_fa = params.get('fa');
const params_dudaSdk = params.get('duda_sdk');
const params_pvd = params.get('pvd');
const params_uytp = params.get('utyp');
const params_thsgid = params.get('thsgid');
const params_hash = params.get('hash');
const params_signed_payload = params.get('signed_payload');
const params_newUser = params.get('newUser');

//check user provider
let _pvd = params_pvd ?? 'shopify';
if (params_pvd) {
  localStorage.setItem('isbusr', false);//white_label
  if (params_uytp) {
    localStorage.setItem('utyp', 'appsumo');//appsumo user
  }
}

const MyRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle Wix site not found error
  if (params_typIntMsg && params_typInt) {
    // Clear local storage and navigate to login page with error message
    localStorage.clear();
    setTimeout(() => {
      dispatch(toastify({ type: 'error', msg: decodeURIComponent(params_typIntMsg) }));
      navigate('/login');
    }, 1000);
  }

  // Track Shopify register event
  if (params_event === 'register') {
    if (window._cio) {
      window._cio.track('new_register_shopify');
    }
    try {
      let tracker = window.ga.getAll()[0];
      tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_shopify');
      //for GA-4
      window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_shopify' });
    } catch (ex) {
      console.log(ex);
    }

    // Google Ads conversion tracking
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC',
        'value': 0,
        'currency': 'INR',
        'transaction_id': localStorage.getItem('ti')
      });
    }
  }

  //page view customer.io
  useEffect(() => {
    if (window._cio) {
      window._cio.page();
    }
  }, [window.location.pathname])

  // Store first plan flag in local storage if applicable
  if (params_pt === 'true') {
    localStorage.setItem('firstPlan', true);
  }

  // Handle login based on user details (if username and JWT token are available)
  if (params_un && params_jwt) {
    // Store user authentication details in local storage
    localStorage.setItem('authToken', params_jwt);
    //update authtoken value
    authToken = localStorage.getItem('authToken');

    localStorage.setItem('username', params_un);
    localStorage.setItem('e', params_un);
    localStorage.setItem('pvd', _pvd);

    // Store sub user status
    if (params_isbusr === 'true') {
      localStorage.setItem('isbusr', true);
    }
    else {
      localStorage.setItem('isbusr', false);
    }

    // Store subscription status if available
    if (params_csub === 'true') {
      localStorage.setItem('csub', 'free');
    }

    // Redirect to the appropriate page (if redirect param exists, use it)
    if (params_rp) {
      navigate(params_rp);
    }
    // else {
    //   if (params_pvd !== 'shopify') {
    //     navigate('/socialproof');
    //   }
    // }

    // Display success toast and reload the page after a slight delay
    setTimeout(() => {
      // dispatch(toastify({ type: 'success', msg: 'Plan assigned successfully' }));
      // navigate('/pixel');
      // window.location.reload();
    }, 200);
  }

  // Handle sub-user login via agency (email is passed as param)
  if (params_email) {
    // Clear local storage for new session
    localStorage.clear();

    // If redirected to plan page, store the redirect URL
    if (params_rp === 'plan') {
      localStorage.setItem('rdt_url', 'plan');
    }

    // Store registration type if available
    if (params_regTyp) {
      localStorage.setItem('reg_typ', params.get('typ'));
    }

    // Redirect to login page with the email parameter
    window.location.href = `${window.location.origin}/login?an=${params_email}`;
  }

  // Handle Duda SDK and WIX local storage setup
  if (params_instance || params_instanceId || params_tgid || params_tgtkn || params_bginstance || params_storeId || (params_thsgid && params_fa === 'duda')) {
    localStorage.clear();
    localStorage.setItem('duda_sdk', params_dudaSdk);
  }

  //get user property
  const isAppsumoUser = localStorage.getItem('utyp') === 'appsumo';
  const isSubUser = localStorage.getItem('isbusr') === 'true';
  const isTeamMember = localStorage.getItem('istm') === 'true';
  const provider = localStorage.getItem('pvd');
  // const hasNoSubscription = !localStorage.getItem('csub') || localStorage.getItem('csub') === 'undefined';
  const isSkipOnboarding = localStorage.getItem('iskponbrdng');
  // const shopCount = localStorage.getItem('shp_cnt');
  const isCompletedOnboarding = localStorage.getItem('iscmpltdonbrdng') === 'true';

  const isRedirectToOnboarding =
    !isAppsumoUser &&
    !isSubUser &&
    !isTeamMember &&
    (provider === 'local' || provider === 'facebook' || provider === 'google') &&
    !isSkipOnboarding &&
    // hasNoSubscription;
    !isCompletedOnboarding;

  useEffect(() => {
    if (isRedirectToOnboarding && authToken) {
      navigate('/onboarding'); // Redirect to the onboarding route
    }
    else if (window.location.pathname === '/onboarding') {
      navigate('/');
    }
  }, [isRedirectToOnboarding, navigate]);

  if (params_hash) {
    let obj = {
      hash: params_hash
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithBGC(obj));
  }
  else if (params_signed_payload) {
    let obj = {
      sp: params_signed_payload
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithBGC(obj));
  } else if (params_bginstance) {
    let obj = {
      bginstance: params_bginstance
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithBGC(obj));
  } else if (params_instanceId) {
    let obj = {
      instanceId: params_instanceId
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithWix(obj));
  } else if (params_instance) {
    let obj = {
      instanceEncode: params_instance,
      path: '/plan'
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithWix(obj));
  } else if (params_tgid) {
    let obj = {
      tgid: params_tgid
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithThinkific(obj));
  } else if (params_tgtkn) {
    let obj = {
      tgtkn: params_tgtkn
    };
    if (params_newUser === true) {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithThinkific(obj));
  }
  else if (params_storeId && params_fa === 'ecwid') {
    let obj = {
      store_id: params_storeId,
      fa: params_fa
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithEcwid(obj));
  } else if (params_storeId && params_fa === 'ecwid') {
    let obj = {
      store_id: params_storeId,
      fa: params_fa
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithEcwid(obj));
  } else if (params_thsgid && params_fa === 'duda') {
    let obj = {
      thsgid: params_thsgid
    };
    // if (newUser === 'true') {
    //   obj.newUser = true;
    // }
    dispatch(authDucks.loginWithDuda(obj));
  }

  return (

    <Suspense fallback={<></>}>
      <Routes>
        {
          !authToken ?
            _isWhiteLabel ?
              <>
                <Route path='*' element={<LazyWhitelabelLogin />} />
                <Route path="/login" element={<LazyWhitelabelLogin />} />
                <Route path="/forgotPwd" element={<LazyWhitelabelForgotPwd />} />
              </>
              :
              <>
                <Route path='*' element={<LazySignUp />} />
                <Route path="/login" element={<LazyLogin />} />
                <Route path="/signup" element={<LazySignUp />} />
                <Route path="/forgotPwd" element={<LazyForgotPwd />} />
                <Route path="/reset" element={<LazyResetPwd />} />
              </>
            :
            isRedirectToOnboarding ?
              <>
                <Route path='/onboarding' element={<LazyOnBoard />} />
                <Route path='*' element={<Navigate to="/onboarding" replace />} />
              </>
              :
              <>
                {/* <Route path='/dashboard' element={<LazyDashboard />} /> */}
                <Route path='/performance' element={<LazyDashboard />} />
                <Route path='/notifShopify' element={<LazyNotifShopify />} />
                <Route path='/notifEdit' element={<LazyNotifEdit />} />
                <Route path='/notification' element={<LazyNotification />} />
                <Route path='/socialproof' element={<LazyNotification />} />
                <Route path='/widgets' element={<LazyNotification />} />
                {
                  localStorage.getItem('isbusr') === 'false' &&
                  <Route path='/inline' element={<LazyNotification />} />
                }
                <Route path='/setting' element={<LazySetting />} />
                <Route path='/data' element={<LazyDataPage />} />
                <Route path='/integration' element={<LazyIntegration />} />
                <Route path='/analytic' element={<LazyAnalytics />} />
                <Route path='/visitor' element={<LazyVisitor />} />
                <Route path='/goal' element={<LazyGoal />} />
                <Route path='/videoGuides' element={<LazyUsers />} />
                <Route path="/pixel" element={<LazyPixel />} />
                <Route path="/profile" element={<LazyManageAccount />} />
                <Route path="/payment" element={<LazyPaymentInvoice />} />
                <Route path="/report" element={<LazyReport />} />
                {
                  localStorage.getItem('isbusr') === 'false' &&
                  <Route path="/refer" element={<LazyReferEarn />} />
                }
                {
                  // isAppsumoUser ?
                  //   <Route path="/plan" element={<LazyAppsumoPlan />} />
                  //   :
                  provider === 'shopify' ?
                    <Route path="/plan" element={<LazyShopifyPricingPlan />} />
                    :
                    provider === 'wix' ?
                      <Route path="/plan" element={<LazyWixPricingPlan />} />
                      :
                      <Route path="/plan" element={<LazyPricingPlan />} />
                }
                {/* {
                  provider === 'shopify' && !shopCount ?
                    <>
                      <Route path='/shopifyAuthRedirect' element={<LazyNotifShopify />} />
                      <Route path='*' element={<Navigate to="/notifShopify" replace />} />
                    </>
                    :
                    <>
                      <Route path='/shopifyAuthRedirect' element={<LazyNotification />} />
                      <Route path='*' element={<Navigate to="/socialproof" replace />} />
                    </>
                } */}
                {
                  provider === 'shopify' ?
                    <>
                      <Route path='/shopifyAuthRedirect' element={<LazyDashboard />} />
                      <Route path='*' element={<Navigate to="/performance" replace />} />
                    </>
                    :
                    <>
                      <Route path='/shopifyAuthRedirect' element={<LazyDashboard />} />
                      <Route path='*' element={<Navigate to="/pixel" replace />} />
                    </>
                }
              </>

        }
      </Routes>
    </Suspense>
  )
}



export default MyRoutes;

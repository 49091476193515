import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const integration = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    getIntegration: (state, action) => { },
    getIntegrationSuccess: (state, action) => {
      return { ...state, integration: action.payload };
    },
    getIntMstList: (state, action) => { },
    getIntMstListSuccess: (state, action) => {
      return { ...state, integrationMst: action.payload };
    },

    getSendInBlue: (state, action) => { },
    createSendInBlue: (state, action) => { },
    deleteSendInBlue: (state, action) => { },

    getConvertkit: (state, action) => { },
    createConvertkit: (state, action) => { },
    deleteConvertkit: (state, action) => { },

    getGoogle: (state, action) => { },
    createGoogle: (state, action) => { },
    deleteGoogle: (state, action) => { },

    getLemlist: (state, action) => { },
    createLemlist: (state, action) => { },
    deleteLemlist: (state, action) => { },

    createAweber: (state, action) => { },
    getAweber: (state, action) => { },
    createAweberList: (state, action) => { },
    deleteList: (state, action) => { },
    deleteAweber: (state, action) => { },
    syncAweberList: (state, action) => { },

    getMailchimp: (state, action) => { },
    createMailchimp: (state, action) => { },
    createMailchimpWebhook: (state, action) => { },
    deleteMC: (state, action) => { },
    deleteMCList: (state, action) => { },

    getFBAC: (state, action) => { },
    createFBAC: (state, action) => { },
    createFBPage: (state, action) => { },
    deleteFBList: (state, action) => { },
    deleteFB: (state, action) => { },

    getBigCommerce: (state, action) => { },
    createBigCommerce: (state, action) => { },
    deleteBigCommerce: (state, action) => { },
    updBigcmWebhook: (state, action) => { },

    getGMB: (state, action) => { },
    createGMB: (state, action) => { },
    createGMBPage: (state, action) => { },
    deletePage: (state, action) => { },
    deleteGMB: (state, action) => { },
    syncGooglePage: (state, action) => { },

    getZap: (state, action) => { },
    createZap: (state, action) => { },
    deleteZap: (state, action) => { },

    getNeto: (state, action) => { },
    createNeto: (state, action) => { },
    deleteNeto: (state, action) => { },

    getCalendly: (state, action) => { },
    createCalendly: (state, action) => { },
    deleteCalendly: (state, action) => { },

    getPl: (state, action) => { },
    deletePl: (state, action) => { },

    getPipedrive: (state, action) => { },
    createPipedrive: (state, action) => { },
    deletePipedrive: (state, action) => { },

    getShopify: (state, action) => { },
    addPixelTag: (state, action) => { },
    deletePixel: (state, action) => { },

    getShopline: (state, action) => { },
    addPixelTagShopline: (state, action) => { },
    deletePixelShopline: (state, action) => { },

    getMailerLite: (state, action) => { },
    createMailerLite: (state, action) => { },
    deleteMailerLite: (state, action) => { },

    getThrivecart: (state, action) => { },
    createThrivecart: (state, action) => { },
    createThrivecartWB: (state, action) => { },
    deleteThrivecartWB: (state, action) => { },
    deleteThrivecart: (state, action) => { },

    getWebflow: (state, action) => { },
    createWebflow: (state, action) => { },
    deleteWebflow: (state, action) => { },

    getCliengo: (state, action) => { },
    createCliengo: (state, action) => { },
    deleteCliengo: (state, action) => { },

    getAutopilot: (state, action) => { },
    createAutopilot: (state, action) => { },
    deleteAutopilot: (state, action) => { },

    getEventbrite: (state, action) => { },
    deleteEventbrite: (state, action) => { },

    getBraintree: (state, action) => { },
    createBraintree: (state, action) => { },
    deleteBraintree: (state, action) => { },

    getScheduleonce: (state, action) => { },
    createScheduleonce: (state, action) => { },
    deleteScheduleonce: (state, action) => { },

    getStripe: (state, action) => { },
    createStripe: (state, action) => { },
    deleteStripe: (state, action) => { },

    getWordpressReview: (state, action) => { },
    createWordpressReview: (state, action) => { },
    deleteWordpressReview: (state, action) => { },

    getAcuityScheduling: (state, action) => { },
    createAcuityScheduling: (state, action) => { },
    deleteAcuityScheduling: (state, action) => { },

    getSendOwl: (state, action) => { },
    createSendOwl: (state, action) => { },
    deleteSendOwl: (state, action) => { },

    getCampaignMonitor: (state, action) => { },
    createCampaignMonitor: (state, action) => { },
    deleteCampaignMonitor: (state, action) => { },

    getBonusly: (state, action) => { },
    createBonusly: (state, action) => { },
    deleteBonusly: (state, action) => { },

    getHubSpot: (state, action) => { },
    createHubSpot: (state, action) => { },
    deleteHubSpot: (state, action) => { },

    getTwitter: (state, action) => { },
    createTwitter: (state, action) => { },
    deleteTwitter: (state, action) => { },

    getYtChannel: (state, action) => { },
    createYtChannel: (state, action) => { },
    deleteYtChannel: (state, action) => { },

    getYtVideo: (state, action) => { },
    getPlayList: (state, action) => { },
    getPlayListSuccess: (state, action) => {
      return { ...state, videoDital: action.payload };
    },
    createVideo: (state, action) => { },
    deleteYtVideo: (state, action) => { },
    deleteYtRecord: (state, action) => { },
    checkVideo: (state, action) => { },
    checkVideoSuccess: (state, action) => {
      return { ...state, playList: action.payload };
    },

    getCapterra: (state, action) => { },
    createCapterra: (state, action) => { },
    deleteCapterra: (state, action) => { },

    getIntegromat: (state, action) => { },
    createIntegromat: (state, action) => { },
    deleteIntegromat: (state, action) => { },

    getTwitFoll: (state, action) => { },
    createTwitFoll: (state, action) => { },
    deleteFollower: (state, action) => { },

    getInstaFoll: (state, action) => { },
    createInstaFoll: (state, action) => { },
    deleteInstaFollower: (state, action) => { },
    linkInstaPage: (state, action) => { },
    deleteInstaList: (state, action) => { },

    getLinkedin: (state, action) => { },
    createLinkedin: (state, action) => { },
    deleteLinkedin: (state, action) => { },
    linkLinkedinPage: (state, action) => { },
    deleteLinkList: (state, action) => { },

    getThinkific: (state, action) => { },
    createThinkific: (state, action) => { },
    deleteThinkific: (state, action) => { },

    getFBLAC: (state, action) => { },
    syncLeadForms: (state, action) => { },
    createFBLAC: (state, action) => { },
    createFBLPage: (state, action) => { },
    deleteFBLList: (state, action) => { },
    deleteFBL: (state, action) => { },

    getCustomWebhook: (state, action) => { },
    createCustomWebhook: (state, action) => { },
    deleteCustomWebhook: (state, action) => { },
    getCustomWebhookField: (state, action) => { },
    successCustomWebhookField: (state, action) => {
      return { ...state, custWBField: action.payload };
    },
    createCustomWebhookFieldUint: (state, action) => { },
    delCustomWebhookFieldUint: (state, action) => { },
    activeCustFld: (state, action) => { },
    updCustField: (state, action) => { },
    successCustomWebhookFieldUserInt: (state, action) => { },
    updIntIsActive: (state, action) => { },
    getNotifForInt: (state, action) => { },
    notlistSuccess: (state, action) => {
      return { ...state, notifList: action.payload };
    },

    getCustomWebform: (state, action) => { },
    createCustomWebform: (state, action) => { },
    deleteCustomWebform: (state, action) => { },
    getWBFormField: (state, action) => { },
    updCWFFld: (state, action) => { },
    createCWFKy: (state, action) => { },
    delCWFKy: (state, action) => { },
    successCustomWBFormField: (state, action) => {
      return { ...state, wbffd: action.payload };
    },

    getIntercom: (state, action) => { },
    createIntercom: (state, action) => { },
    deleteIntercom: (state, action) => { },

    getSendfox: (state, action) => { },
    createSendfox: (state, action) => { },
    addSendfoxList: (state, action) => { },
    deleteSendfoxList: (state, action) => { },
    deleteSendfox: (state, action) => { },

    getAutomizy: (state, action) => { },
    createAutomizy: (state, action) => { },
    addAutomizyList: (state, action) => { },
    deleteAutomizyList: (state, action) => { },
    deleteAutomizy: (state, action) => { },

    getYelp: (state, action) => { },
    createYelp: (state, action) => { },
    deleteYelp: (state, action) => { },

    getLivewebinar: (state, action) => { },
    createLivewebinar: (state, action) => { },
    deleteLivewebinar: (state, action) => { },

    getAmember: (state, action) => { },
    createAmember: (state, action) => { },
    deleteAmember: (state, action) => { },

    getNewzenler: (state, action) => { },
    createNewzenler: (state, action) => { },
    deleteNewzenler: (state, action) => { },

    getYotpo: (state, action) => { },
    createYotpo: (state, action) => { },
    deleteYotpo: (state, action) => { },

    getRepuso: (state, action) => { },
    createRepuso: (state, action) => { },
    deleteRepuso: (state, action) => { },

    getHeysummit: (state, action) => { },
    createHeysummit: (state, action) => { },
    createHeySummitWebhook: (state, action) => { },
    deleteHeysummit: (state, action) => { },
    syncHeysummitEvent: (state, action) => { },
    hsSuccessEventData: (state, action) => {
      return { ...state, hsEventData: action.payload };
    },

    getEdd: (state, action) => { },
    deleteEdd: (state, action) => { },

    getElitefunnels: (state, action) => { },
    createElitefunnels: (state, action) => { },
    deleteElitefunnels: (state, action) => { },

    getFastfunnels: (state, action) => { },
    createFastfunnels: (state, action) => { },
    deleteFastfunnels: (state, action) => { },

    getGetdrip: (state, action) => { },
    createGetdrip: (state, action) => { },
    deleteGetdrip: (state, action) => { },

    deleteARAL: (state, action) => { },
    createAlTR: (state, action) => { },
    getSpotify: (state, action) => { },
    createSpotify: (state, action) => { },
    deleteSpotify: (state, action) => { },

    getOpenCart: (state, action) => { },
    deleteOpenCart: (state, action) => { },

    getSurveymonkey: (state, action) => { },
    createSMWB: (state, action) => { },
    deleteSMWB: (state, action) => { },
    deleteSurveyMonkey: (state, action) => { },

    getTwitchTV: (state, action) => { },
    createTwitchTV: (state, action) => { },
    deleteTwitchTV: (state, action) => { },

    getImportreview: (state, action) => { },
    createImportReview: (state, action) => { },
    deleteImportReview: (state, action) => { },

    getPrestashop: (state, action) => { },
    deletePrestashop: (state, action) => { },

    getWishpond: (state, action) => { },
    createWishpond: (state, action) => { },
    deleteWishpond: (state, action) => { },

    getZoomai: (state, action) => { },
    createZoomai: (state, action) => { },
    deleteZoomai: (state, action) => { },

    getBooking: (state, action) => { },
    createBooking: (state, action) => { },
    deleteBooking: (state, action) => { },

    getTrustpilot: (state, action) => { },
    createTrustpilot: (state, action) => { },
    deleteTrustpilot: (state, action) => { },

    getOntraport: (state, action) => { },
    createOntraport: (state, action) => { },
    deleteOntraport: (state, action) => { },

    getTRPA: (state, action) => { },
    createTRPA: (state, action) => { },
    deleteTRPA: (state, action) => { },

    getStamped: (state, action) => { },
    createStamped: (state, action) => { },
    deleteStamped: (state, action) => { },

    getClixli: (state, action) => { },
    createClixli: (state, action) => { },
    deleteClixli: (state, action) => { },

    getAirbnb: (state, action) => { },
    createAirbnb: (state, action) => { },
    deleteAirbnb: (state, action) => { },

    getWiserReview: (state, action) => { },
    createWiserReview: (state, action) => { },
    deleteWiserReview: (state, action) => { },

    getWix: (state, action) => { },
    createWix: (state, action) => { },
    deleteWix: (state, action) => { },

    getReviewio: (state, action) => { },
    createReviewio: (state, action) => { },
    deleteReviewio: (state, action) => { },

    getSimvoly: (state, action) => { },
    createSimvoly: (state, action) => { },
    deleteSimvoly: (state, action) => { },

    getModwebs: (state, action) => { },
    createModwebs: (state, action) => { },
    deleteModwebs: (state, action) => { },

    getAppSumo: (state, action) => { },
    createAppSumo: (state, action) => { },
    deleteAppSumo: (state, action) => { },

    getPracto: (state, action) => { },
    createPracto: (state, action) => { },
    deletePracto: (state, action) => { },

    getGooglePlay: (state, action) => { },
    createGooglePlay: (state, action) => { },
    deleteGooglePlay: (state, action) => { },

    deleteEcwid: (state, action) => { },
    getEcwid: (state, action) => { },

    getFBGrp: (state, action) => { },
    createFBGrp: (state, action) => { },
    deleteFBGrp: (state, action) => { },
    deleteFBGrpAcc: (state, action) => { },
    syncGroups: (state, action) => { },

    getEncharge: (state, action) => { },
    createEncharge: (state, action) => { },
    deleteEncharge: (state, action) => { },

    get3dCart: (state, action) => { },

    getAmazon: (state, action) => { },
    createAmazon: (state, action) => { },
    deleteAmazon: (state, action) => { },

    getDealerRater: (state, action) => { },
    createDealerRater: (state, action) => { },
    deleteDealerRater: (state, action) => { },

    getAppleApp: (state, action) => { },
    createAppleApp: (state, action) => { },
    deleteAppleApp: (state, action) => { },

    getBBB: (state, action) => { },
    createBBB: (state, action) => { },
    deleteBBB: (state, action) => { },

    geteBay: (state, action) => { },
    createeBay: (state, action) => { },
    deleteeBay: (state, action) => { },

    getEdmunds: (state, action) => { },
    createEdmunds: (state, action) => { },
    deleteEdmunds: (state, action) => { },

    getZoom: (state, action) => { },

    getEtsy: (state, action) => { },
    createEtsy: (state, action) => { },
    deleteEtsy: (state, action) => { },

    getHotels: (state, action) => { },
    createHotels: (state, action) => { },
    deleteHotels: (state, action) => { },

    getHouzz: (state, action) => { },
    createHouzz: (state, action) => { },
    deleteHouzz: (state, action) => { },

    getOpenTable: (state, action) => { },
    createOpenTable: (state, action) => { },
    deleteOpenTable: (state, action) => { },

    getMyDukkan: (state, action) => { },
    deleteMyDukkan: (state, action) => { },

    getInstaPostInt: (state, action) => { },
    linkInstaPostPage: (state, action) => { },
    deleteInstaPostList: (state, action) => { },
    deleteInstaPost: (state, action) => { },

    getTikTok: (state, action) => { },
    createTikTok: (state, action) => { },
    deleteTikTok: (state, action) => { },

    getCommercetools: (state, action) => { },
    createCommercetools: (state, action) => { },
    deleteCommercetools: (state, action) => { },

    getElopage: (state, action) => { },
    createElopage: (state, action) => { },
    deleteElopage: (state, action) => { },

    getG2: (state, action) => { },
    createG2: (state, action) => { },
    deleteG2: (state, action) => { },

    getSquarespace: (state, action) => { },
    deleteSquarespace: (state, action) => { },

    getShopperApproved: (state, action) => { },
    createShopperApproved: (state, action) => { },
    deleteShopperApproved: (state, action) => { },

    getnopCommerce: (state, action) => { },
    deletenopCommerce: (state, action) => { },

    getImages: (state, action) => { },
    iconSuccess: (state, action) => {
      return { ...state, iconImages: action.payload };
    },

    getWebhook: (state, action) => { },
    createWebhook: (state, action) => { },
    deleteWebhook: (state, action) => { },
    successWebhook: (state, action) => {
      return { ...state, webhookList: action.payload };
    },

    addCutomReview: (state, action) => { },
    updateReview: (state, action) => { },
    updateReviewSuccess: (state, action) => { },
    sucessReviewData: (state, action) => { },

    uploadCSV: (state, action) => { },
    deleteImage: (state, action) => { },

    intSuccess: (state, action) => {
      return { ...state, userIntData: action.payload };
    },

    intClear: (state, action) => {
      return { ...state, userIntData: {} };
    },
  }
});

export const {
  getWebhook,
  createWebhook,
  deleteWebhook,
  successWebhook,

  getnopCommerce,
  deletenopCommerce,

  getShopperApproved,
  createShopperApproved,
  deleteShopperApproved,

  getSquarespace,
  deleteSquarespace,

  getG2,
  createG2,
  deleteG2,

  getElopage,
  createElopage,
  deleteElopage,

  getCommercetools,
  createCommercetools,
  deleteCommercetools,

  getTikTok,
  createTikTok,
  deleteTikTok,

  getInstaPostInt,
  linkInstaPostPage,
  deleteInstaPostList,
  deleteInstaPost,

  getMyDukkan,
  deleteMyDukkan,

  getOpenTable,
  createOpenTable,
  deleteOpenTable,

  getHotels,
  createHotels,
  deleteHotels,

  getEtsy,
  createEtsy,
  deleteEtsy,

  getZoom,

  getEdmunds,
  createEdmunds,
  deleteEdmunds,

  geteBay,
  createeBay,
  deleteeBay,

  getBBB,
  createBBB,
  deleteBBB,

  getAppleApp,
  createAppleApp,
  deleteAppleApp,

  getDealerRater,
  createDealerRater,
  deleteDealerRater,

  getAmazon,
  createAmazon,
  deleteAmazon,

  get3dCart,

  getEncharge,
  createEncharge,
  deleteEncharge,

  getFBGrp,
  createFBGrp,
  deleteFBGrp,
  deleteFBGrpAcc,
  syncGroups,

  deleteEcwid,
  getEcwid,

  getIntegration,
  getIntegrationSuccess,
  getIntMstList,
  getIntMstListSuccess,

  getSendInBlue,
  createSendInBlue,
  deleteSendInBlue,

  getConvertkit,
  createConvertkit,
  deleteConvertkit,

  getGoogle,
  createGoogle,
  deleteGoogle,

  getLemlist,
  createLemlist,
  deleteLemlist,

  createAweber,
  getAweber,
  createAweberList,
  deleteList,
  deleteAweber,
  syncAweberList,

  getMailchimp,
  createMailchimp,
  createMailchimpWebhook,
  deleteMC,
  deleteMCList,

  getFBAC,
  createFBAC,
  createFBPage,
  deleteFBList,
  deleteFB,

  getBigCommerce,
  createBigCommerce,
  deleteBigCommerce,
  updBigcmWebhook,

  getGMB,
  createGMB,
  createGMBPage,
  deletePage,
  deleteGMB,
  syncGooglePage,

  getZap,
  createZap,
  deleteZap,

  getNeto,
  createNeto,
  deleteNeto,

  getCalendly,
  createCalendly,
  deleteCalendly,

  getPl,
  deletePl,

  getPipedrive,
  createPipedrive,
  deletePipedrive,

  getShopify,
  addPixelTag,
  deletePixel,

  getShopline,
  addPixelTagShopline,
  deletePixelShopline,

  getMailerLite,
  createMailerLite,
  deleteMailerLite,

  getThrivecart,
  createThrivecart,
  createThrivecartWB,
  deleteThrivecartWB,
  deleteThrivecart,

  getWebflow,
  createWebflow,
  deleteWebflow,

  getCliengo,
  createCliengo,
  deleteCliengo,

  getAutopilot,
  createAutopilot,
  deleteAutopilot,

  getEventbrite,
  deleteEventbrite,

  getBraintree,
  createBraintree,
  deleteBraintree,

  getScheduleonce,
  createScheduleonce,
  deleteScheduleonce,

  getStripe,
  createStripe,
  deleteStripe,

  getWordpressReview,
  createWordpressReview,
  deleteWordpressReview,

  getAcuityScheduling,
  createAcuityScheduling,
  deleteAcuityScheduling,

  getSendOwl,
  createSendOwl,
  deleteSendOwl,

  getCampaignMonitor,
  createCampaignMonitor,
  deleteCampaignMonitor,

  getBonusly,
  createBonusly,
  deleteBonusly,

  getHubSpot,
  createHubSpot,
  deleteHubSpot,

  getTwitter,
  createTwitter,
  deleteTwitter,

  getYtChannel,
  createYtChannel,
  deleteYtChannel,

  getYtVideo,
  getPlayList,
  getPlayListSuccess,
  createVideo,
  deleteYtVideo,
  deleteYtRecord,
  checkVideo,
  checkVideoSuccess,

  getCapterra,
  createCapterra,
  deleteCapterra,

  getIntegromat,
  createIntegromat,
  deleteIntegromat,

  getTwitFoll,
  createTwitFoll,
  deleteFollower,

  getInstaFoll,
  createInstaFoll,
  deleteInstaFollower,
  linkInstaPage,
  deleteInstaList,

  getLinkedin,
  createLinkedin,
  deleteLinkedin,
  linkLinkedinPage,
  deleteLinkList,

  getThinkific,
  createThinkific,
  deleteThinkific,

  getFBLAC,
  syncLeadForms,
  createFBLAC,
  createFBLPage,
  deleteFBLList,
  deleteFBL,

  getCustomWebhook,
  createCustomWebhook,
  deleteCustomWebhook,
  getCustomWebhookField,
  successCustomWebhookField,
  createCustomWebhookFieldUint,
  delCustomWebhookFieldUint,
  activeCustFld,
  updCustField,
  successCustomWebhookFieldUserInt,
  updIntIsActive,

  getCustomWebform,
  createCustomWebform,
  deleteCustomWebform,
  getWBFormField,
  updCWFFld,
  createCWFKy,
  delCWFKy,
  successCustomWBFormField,

  getIntercom,
  createIntercom,
  deleteIntercom,

  getSendfox,
  createSendfox,
  addSendfoxList,
  deleteSendfoxList,
  deleteSendfox,

  getAutomizy,
  createAutomizy,
  addAutomizyList,
  deleteAutomizyList,
  deleteAutomizy,

  getYelp,
  createYelp,
  deleteYelp,

  getLivewebinar,
  createLivewebinar,
  deleteLivewebinar,

  getAmember,
  createAmember,
  deleteAmember,

  getNewzenler,
  createNewzenler,
  deleteNewzenler,

  getYotpo,
  createYotpo,
  deleteYotpo,

  getRepuso,
  createRepuso,
  deleteRepuso,

  getHeysummit,
  createHeysummit,
  createHeySummitWebhook,
  deleteHeysummit,
  syncHeysummitEvent,
  hsSuccessEventData,

  getEdd,
  deleteEdd,

  getElitefunnels,
  createElitefunnels,
  deleteElitefunnels,

  getFastfunnels,
  createFastfunnels,
  deleteFastfunnels,

  getGetdrip,
  createGetdrip,
  deleteGetdrip,

  deleteARAL,
  createAlTR,
  getSpotify,
  createSpotify,
  deleteSpotify,

  getOpenCart,
  deleteOpenCart,

  getSurveymonkey,
  createSMWB,
  deleteSMWB,
  deleteSurveyMonkey,

  getTwitchTV,
  createTwitchTV,
  deleteTwitchTV,

  getImportreview,
  createImportReview,
  deleteImportReview,

  getPrestashop,
  deletePrestashop,

  getWishpond,
  createWishpond,
  deleteWishpond,

  getZoomai,
  createZoomai,
  deleteZoomai,

  getBooking,
  createBooking,
  deleteBooking,

  getTrustpilot,
  createTrustpilot,
  deleteTrustpilot,

  getTRPA,
  createTRPA,
  deleteTRPA,

  getOntraport,
  createOntraport,
  deleteOntraport,

  getStamped,
  createStamped,
  deleteStamped,

  getClixli,
  createClixli,
  deleteClixli,

  getAirbnb,
  createAirbnb,
  deleteAirbnb,

  getWiserReview,
  createWiserReview,
  deleteWiserReview,

  getWix,
  createWix,
  deleteWix,

  getReviewio,
  createReviewio,
  deleteReviewio,

  getSimvoly,
  createSimvoly,
  deleteSimvoly,

  getModwebs,
  createModwebs,
  deleteModwebs,

  getAppSumo,
  createAppSumo,
  deleteAppSumo,

  getPracto,
  createPracto,
  deletePracto,

  getGooglePlay,
  createGooglePlay,
  deleteGooglePlay,

  getHouzz,
  createHouzz,
  deleteHouzz,
  getNotifForInt,
  notlistSuccess,

  getImages,
  iconSuccess,

  addCutomReview,
  updateReview,
  updateReviewSuccess,
  sucessReviewData,

  uploadCSV,
  deleteImage,

  intClear,
  intSuccess
} = integration.actions;

export default integration.reducer;

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './Store';
import Spinner from './components/Spinner/Spinner';
import EntryCard from './components/Layout/EntryCard';
import ShopifyInitSkeleton from './components/Layout/ShopifyInitSkeleton';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import { BrowserRouter } from 'react-router-dom';
import Toastify from './components/Util/Toastify';
import * as Sentry from '@sentry/react';
import en from "@shopify/polaris/locales/en.json";
import './App.css';
import ErrorBoundaryUI from './components/ErrorBoundaryUI/ErrorBoundaryUI';
import { onLCP, onFID, onINP, onCLS, } from 'web-vitals';

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: 'https://4c2fdfdae59dd3b47ab13f7f9f3fbc21@o992599.ingest.us.sentry.io/4507978256678912',
    tracesSampleRate: 1.0,
    beforeSend(event) {

      // Filter out specific events
      const errors = ['Cloudflare Turnstile', 'Unexpected token'];
      const targetError = event.exception?.values[0]?.value?.toString();

      return !errors.some(x => targetError.indexOf(x) !== -1) ? event : null;
    },

    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
      Sentry.browserTracingIntegration()
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  if (localStorage.getItem('e')) {
    Sentry.setUser({ email: localStorage.getItem('e') });
  }
}

let authToken = localStorage.getItem('authToken');


const App = (props) => {
  //#region start code for shopify app bridge
  //Extracting parameters from the URL query string
  const paramsShop = new URLSearchParams(window.location.search);
  const shop = paramsShop.get('shop');//"hdkmobilestore.myshopify.com";
  const host = paramsShop.get('host');//"YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvaGRrbW9iaWxlc3RvcmU";
  const scopes = paramsShop.get('scopes');
  const is_shopify_embed = paramsShop.get('is_embeded');
  const custom_domain = paramsShop.get('custom_domain');
  const apiKey = process.env.REACT_APP_SHOPIFY_KEY;

  // Redirecting to Shopify OAuth authorization if scopes are provided
  if (scopes) {
    window.parent.location.href = 'https://' + shop + '/admin/oauth/authorize?client_id=' + process.env.REACT_APP_SHOPIFY_KEY + '&scope=' + scopes + '&redirect_uri=' + process.env.REACT_APP_API_URL + '/shopify/callbackOauth&&host=' + host + '&shop=' + shop;
  }
  else if (apiKey && shop) {
    const config = {
      apiKey: apiKey,
      shopOrigin: shop,
      host: host,
      forceRedirect: true,
    }
    //Store Shopify store authentication information in localStorage. We need to use this information to start listening for menu clicks in the sidebar page because we can't use the navigate function in the app.js file.
    sessionStorage.setItem('shop_name', shop);
    localStorage.setItem('store_config', JSON.stringify(config))
  }

  // Checking if the app is rendered inside the Shopify admin
  if (is_shopify_embed) {
    localStorage.setItem('is_shopify_embed', true);
  }
  if (custom_domain) {
    localStorage.setItem('shopify_custom_domain', custom_domain);
  }
  //#endregion 

  const params = new URLSearchParams(window.location.search);
  //set user token to localstorage 
  let param_tkn = params.get('tkn');
  let param_tkn_shopify = params.get('q');

  if (param_tkn || param_tkn_shopify) {
    localStorage.setItem('authToken', param_tkn || param_tkn_shopify);
    //update authtoken value
    authToken = localStorage.getItem('authToken');
  }

  if (window.Chargebee) {
    window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
      enableGTMTracking: JSON.parse(process.env.REACT_APP_CHARGEBEE_ENABLE_GTM_TRACKING || 'false'),
      iframeOnly: true
    });
  }

  useEffect(() => {
    // Log web vitals metrics to the console
    onLCP(console.log); // Largest Contentful Paint
    onFID(console.log); // First Input Delay
    onCLS(console.log); // Cumulative Layout Shift
    onINP(console.log); // Interaction to Next Paint
  }, []);

  // Automatically update account across all tabs on logout (Auto-logout across all tabs when logged out in one.)
  useEffect(() => {
    const redirectAuth = () => {
      const intervalId = setInterval(() => {
        const currentAuthToken = localStorage.getItem('authToken');
        if (!currentAuthToken && authToken !== currentAuthToken) {
          if (!currentAuthToken) {
            window.location.href = '/login'; //on logout
          }
          else window.location.reload();
        }
      }, 5000);
      return () => clearInterval(intervalId);
    };
    redirectAuth();
  }, [authToken]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryUI />} showDialog>
      <Provider store={store}>
        <AppProvider i18n={en}>
          <Spinner />
          <Toastify />
          <BrowserRouter>
            {
              props.Component === 'shopifyInitLoading' ?
                <ShopifyInitSkeleton />
                :
                authToken ?
                  <EntryCard component={<props.Component />} />
                  :
                  <props.Component />
            }
          </BrowserRouter>
        </AppProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;

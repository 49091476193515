import React, { useCallback, useEffect, useState } from 'react';
import { Badge, BlockStack, Box, Card, InlineGrid, InlineStack, ProgressBar, SkeletonBodyText, SkeletonDisplayText, Text } from '@shopify/polaris';
import { plan_Close_icon, planTrue_icon, reload_icon } from '../../img';
import { useDispatch, useSelector } from 'react-redux';
import * as NotifShopifyDucks from '../../ducks/notifShopify';
import * as AppsumoPlanDucks from '../../ducks/appsumoPlan';
import { XIcon } from '@shopify/polaris-icons';
let _width;
let usedQuota = 0;

function AppsumoPlanCard() {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    profile: {},
    planUse: 0,
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data.
  const appsumoplan = useSelector(state => state.appsumoPlan.appsumoPlan);
  const profileState = useSelector(state => state.auth.profile);
  const isLoading = useSelector(state => state.loading.isLoading);

  let isBrand = true;
  let isWhite = true;

  if (profileState && profileState.utyp === 'appsumo' && profileState.apsmver === 2 && profileState.apstk.length === 1) {
    isBrand = false;
    isWhite = false;
  }

  const includeList = [
    { icon: planTrue_icon, label: 'All notifications types' },
    { icon: planTrue_icon, label: 'Reporting, and analytics' },
    { icon: isBrand === true ? planTrue_icon : plan_Close_icon, label: 'Branding control' },
    { icon: planTrue_icon, label: 'Unlimited website' },
    { icon: planTrue_icon, label: 'All advanced features' },
    { icon: isWhite === true ? planTrue_icon : plan_Close_icon, label: 'White label' },
    { icon: planTrue_icon, label: 'Unlimited notifications' },
    { icon: planTrue_icon, label: 'Unlimited staff, sub-users' }
  ]

  useEffect(() => {
    dispatch(AppsumoPlanDucks.getAppsumoPlan());
  }, []);

  //set planUse state data.
  useEffect(() => {
    if (profileState) {
      const planUse = (profileState.uuq / profileState.uaq) * 100;
      let obj = {
        profile: profileState,
        planUse: parseFloat(planUse.toFixed())
      }
      changeNameValue(obj);
    }
  }, [profileState]);

  useEffect(() => {
    let _uuq = state.profile.uuq;
    if (state.profile.suaq) {
      _uuq = _uuq + state.profile.suaq;
    }
    if (state.profile.puuq) {
      _uuq = _uuq + state.profile.puuq;
    }

    let planUse = _uuq / state.profile.uaq * 100;
    changeNameValue({ planUse: planUse });
  }, [state.profile])

  useEffect(() => {
    if (state.planUse) {
      if (state.planUse > 100) {
        _width = '100';
      }
      else {
        _width = `${state.planUse}`;
      }
      if (state.planUse.toString() === 'NaN') {
        state.planUse = 0;
      }
    }

    // usedQuota
    if (profileState) {
      if (profileState.uuq > profileState.uaq) {
        usedQuota = profileState.uaq;
      }
      else {
        if (profileState.suaq) {
          usedQuota = profileState.uuq + profileState.suaq;
          if (usedQuota > profileState.uaq) {
            usedQuota = profileState.uaq;
          }
        }
        else {
          usedQuota = profileState.uuq;
        }
      }
      if (profileState.puuq) {
        usedQuota = usedQuota + profileState.puuq;
      }
    }

  }, [state.planUse, profileState]);

  //handle Get Uniq Cron.
  const handleGetUniqCron = () => {
    const ti = localStorage.getItem('ti');
    dispatch(NotifShopifyDucks.getUniqCron(ti));
  }

  //formmet number.
  const formmetNumber = (nu) => {
    return nu.toLocaleString();
  }

  return (
    <div className='card-active-plan'>
      {isLoading ?
        <Card>
          <BlockStack gap={400}>
            <SkeletonDisplayText size='small' />
            <Box>
              <SkeletonDisplayText maxWidth='100%' />
            </Box>
            <SkeletonDisplayText size='small' />
            <SkeletonBodyText />
          </BlockStack>
        </Card>
        :
        <Card>
          <BlockStack gap={400}>
            <InlineStack gap={200} blockAlign='center'>
              <Text variant='headingMd' as='h4' fontWeight='semibold'>Current plan</Text>
              <Badge tone='success'>{appsumoplan && appsumoplan?.plan?.ttl ? appsumoplan?.plan?.ttl : '-'}</Badge>
              <Badge>{`${appsumoplan && appsumoplan?.plan?.duaq ? appsumoplan?.plan?.duaq : '-'} Monthly Visitors`}</Badge>
            </InlineStack>
            <Box>
              <BlockStack gap={200}>
                <InlineStack align='space-between' blockAlign='center'>
                  <InlineStack gap={500} blockAlign='center' wrap={false}>
                    {/* <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{`Monthly Visitors Used: ${state.profile?.uuq ? formmetNumber(state.profile?.uuq) : 0} / ${state.profile?.uaq ? formmetNumber(state.profile?.uaq) : 0} (${state.planUse.toFixed() > 100 ? '100' : state.planUse.toFixed()}%)`}</Text> */}
                    <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{`Visitors Used: ${state.profile && usedQuota ? formmetNumber(usedQuota) : 0} of your ${state.profile && state.profile?.uaq ? formmetNumber(state.profile?.uaq) : 0} visitors monthly`}</Text>
                    <InlineStack gap={100} wrap={false}>
                      <img src={reload_icon} alt='reload' />
                      <div onClick={() => handleGetUniqCron()} style={{ cursor: "pointer" }}>
                        <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>Refresh</Text>
                      </div>
                    </InlineStack>
                  </InlineStack>
                  <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{state.planUse.toFixed() > 100 ? '100' : state.planUse.toFixed()}%</Text>
                </InlineStack>
                <ProgressBar progress={_width} size="small" />
              </BlockStack>
            </Box>
            <Box>
              <Text variant='headingMd' as='h4' fontWeight='semibold'>Includes</Text>
              <Box paddingBlockStart={300}>
                <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={100}>
                  {includeList && includeList.map((data, index) => (
                    <InlineStack key={index} gap={100} blockAlign='center'>
                      <img src={data.icon} alt='' />
                      <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                    </InlineStack>
                  ))}
                </InlineGrid>
              </Box>
            </Box>
          </BlockStack>
        </Card>
      }
    </div>
  );
}

export default AppsumoPlanCard;
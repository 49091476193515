import React, { useCallback, useState } from 'react';
import { Navigation } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
// import { SettingsIcon, DatabaseIcon, MarketsIcon, AffiliateIcon, ChannelsIcon, UnknownDeviceIcon, PersonFilledIcon, PlanFilledIcon, ChartVerticalFilledIcon, ExternalIcon } from '@shopify/polaris-icons';
import { MetafieldsFilledIcon, MetafieldsIcon, SunIcon, ExternalIcon, CashDollarIcon, CashDollarFilledIcon, PersonIcon, BillIcon, BillFilledIcon, AppsFilledIcon, AppsIcon, ChartVerticalIcon, SettingsFilledIcon, TextInRowsFilledIcon, TextInRowsIcon, FlowerFilledIcon, QuestionCircleIcon, ViewIcon, SettingsIcon, DatabaseIcon, MarketsIcon, AffiliateIcon, ChannelsIcon, UnknownDeviceIcon, PersonFilledIcon, PlanFilledIcon, ChartVerticalFilledIcon, ContractFilledIcon, ContractIcon } from '@shopify/polaris-icons';
import { checkPermission } from '../../services/permissions';
import { useSelector } from 'react-redux';

function Sidebar(props) {
  const navigate = useNavigate();
  let path = window.location.pathname;
  const params = new URLSearchParams(window.location.search);
  const ntypValue = params.get('ntyp');

  const _dataNotificationCount = useSelector(state => state.notif?.notifByUser?.notifBycount);

  if (!window.dataNotificationCount) {
    window.dataNotificationCount = _dataNotificationCount;
  }



  if (ntypValue !== null) {
    ntypValue == 'widget' ? path = '/widgets' : ntypValue == 'inlinenudge' ? path = '/inline' : path = '/socialproof'
  }

  const [menu, setMenu] = useState({
    selectedParent: ''
  });

  const changeRoute = useCallback((pathName, isParent, isSubMenu) => {
    //If the clicked menu is a parent menu, we open page and open the submenu section
    if (isParent) {
      setMenu({ selectedParent: pathName });
    }

    navigate(pathName);

    //If the clicked menu is not a sub-menu, close the parent menu section.
    if (!isParent && !isSubMenu) {
      setMenu({ selectedParent: '' });
    }
  }, [navigate]);

  const clickSendOutside = (name, link) => {
    if (window.ga) {
      try {
        let tracker = window.ga.getAll()[0];
        tracker.send('event', 'Profile Menu Click', name, localStorage.getItem('e') || '', { transport: 'beacon' });
        //for GA-4
        window.gtag('event', 'Profile Menu Click', { 'event_action': name, 'event_label': localStorage.getItem('e') || '' });
      } catch (error) { console.log(error); }
    }

    window.open(link, '_blank');
  }

  return (
    <div>
      <Navigation location={path}>
        <Navigation.Section
          // separator
          // fill
          items={[
            // {
            //   url: '#',
            //   label: 'Notifications Shopify',
            //   icon: CheckboxIcon,
            //   selected: path === '/notifShopify',
            //   onClick: () => changeRoute('notifShopify'), 
            // },
            {
              url: '#',
              label: 'Social proof',
              icon: (path === '/socialproof' || path === '/') ? TextInRowsIcon : TextInRowsIcon,
              badge: window.dataNotificationCount?.lineCount?.toString(),
              selected: (path === '/socialproof' || path === '/'),
              onClick: () => changeRoute('socialproof', true, false),
              subNavigationItems: menu.selectedParent.indexOf['socialproof,widget,inline'] !== -1 ?
                [
                  {
                    url: path === '/widgets' ? '#' : '/widgets',
                    disabled: false,
                    label: 'Widgets',
                    badge: window.dataNotificationCount?.widgetCount?.toString(),
                    selected: path === '/widgets',
                    onClick: () => changeRoute('widgets', false, true),
                  },
                  localStorage.getItem('isbusr') === 'false' &&
                  {
                    url: path === '/inline' ? '#' : '/inline',
                    disabled: false,
                    label: 'Inline',
                    badge: window.dataNotificationCount?.inlineCount?.toString(),
                    selected: path === '/inline',
                    onClick: () => changeRoute('inline', false, true),
                  }
                ] :
                []
            },
            {
              // url: '#',
              label: 'Settings',
              icon: path === '/setting' ? SettingsIcon : SettingsFilledIcon,
              selected: path === '/setting',
              onClick: () => changeRoute('setting')
            },
            {
              url: '#',
              label: 'Performance',
              icon: path === '/performance' ? ChartVerticalIcon : ChartVerticalFilledIcon,
              selected: path === '/performance' ? true : false,
              onClick: () => changeRoute('performance', true, false),
              subNavigationItems: menu.selectedParent.indexOf['analytic,performance,visitor,goal'] !== -1 ?
                [
                  {
                    url: path === '/analytic' ? '#' : '/analytic',
                    disabled: false,
                    label: 'Analytics',
                    selected: path === '/analytic',
                    onClick: () => changeRoute('analytic', false, true),
                  },
                  {
                    url: path === '/visitor' ? '#' : '/visitor',
                    disabled: false,
                    label: 'Visitor',
                    selected: path === '/visitor',
                    onClick: () => changeRoute('visitor', false, true),
                  },
                  {
                    url: path === '/goal' ? '#' : '/goal',
                    disabled: false,
                    selected: path === '/goal',
                    label: 'Goal',
                    onClick: () => changeRoute('goal', false, true)
                  }
                ]
                :
                [],
            }
          ]} />

        <Navigation.Section
          title="Sources"
          items={[
            {
              // url: '#',
              label: 'Data',
              icon: path === '/data' ? `<svg  viewBox="0 0 12 12"  xmlns="http://www.w3.org/2000/svg">
<path d="M6.00039 0C8.98244 0 11.4004 0.839531 11.4004 1.875V10.125C11.4004 11.1605 8.98244 12 6.00039 12C3.01834 12 0.600392 11.1605 0.600392 10.125V1.875C0.600392 0.839531 3.01834 0 6.00039 0ZM6.00039 0.75C3.03521 0.75 1.46584 1.575 1.37182 1.875L1.37144 3.75188C2.6037 4.22344 4.24539 4.5 6.00039 4.5C7.75539 4.5 9.39709 4.22344 10.6302 3.75234L10.6304 1.88836C10.5347 1.575 8.98727 0.75 6.00039 0.75ZM1.37182 4.53516L1.37126 7.50234C2.6037 7.97344 4.24539 8.25 6.00039 8.25C7.75563 8.25 9.39709 7.9725 10.629 7.50164L10.6299 4.53281C9.33489 4.98047 7.71923 5.25 6.02209 5.25C4.28155 5.25 2.66637 4.98047 1.37182 4.53516ZM6.00039 11.25C8.96557 11.25 10.5349 10.425 10.629 10.125L10.6293 8.28398C9.33441 8.73047 7.71923 9 6.02209 9C4.30301 9 2.68807 8.73094 1.39111 8.28375L1.39078 10.1114C1.46584 10.425 3.03521 11.25 6.00039 11.25Z"/>
</svg>` : `<svg  viewBox="0 0 11 12"  xmlns="http://www.w3.org/2000/svg">
<path d="M10.2857 1.71375V2.78602C10.2857 3.72891 7.98061 4.5 5.14286 4.5C2.3051 4.5 0 3.72891 0 2.78672V1.71375C0 0.770625 2.3051 0 5.14286 0C7.98061 0 10.2857 0.770625 10.2857 1.71375ZM10.2857 4.125V6.53672C10.2857 7.47891 7.98061 8.25 5.14286 8.25C2.3051 8.25 0 7.47891 0 6.53672V4.125C1.1048 4.90125 3.12704 5.26453 5.14286 5.26453C7.15867 5.26453 9.18138 4.90078 10.2857 4.125ZM10.2857 7.875V10.2867C10.2857 11.2289 7.98061 12 5.14286 12C2.3051 12 0 11.2294 0 10.286V7.875C1.1048 8.65148 3.12704 9.01477 5.14286 9.01477C7.15867 9.01477 9.18138 8.65078 10.2857 7.875Z"/>
</svg>
`,
              selected: path === '/data',
              onClick: () => changeRoute('data')
            },
            {
              label: 'Integrations',
              icon: path === '/integration' ? AppsIcon : AppsFilledIcon,
              selected: path === '/integration',
              onClick: () => changeRoute('integration')
            }
          ]}
        />
        <Navigation.Section
          separator
          fill
          title="Others"
          items={[
            checkPermission('plan', 'isview', props.profileData) && localStorage.getItem('isbusr') === 'false' && localStorage.getItem('istm') !== 'true' &&
            {
              url: '#',
              label: 'Plan',
              icon: path === '/plan' ? BillIcon : BillFilledIcon,
              selected: path === '/plan',
              onClick: () => changeRoute('plan', true, false),
              // badge: 'Black Friday Sale',
              subNavigationItems: menu.selectedParent.indexOf['plan,payment'] !== -1 ?
                [
                  {
                    url: path === '/payment' ? '#' : '/payment',
                    disabled: false,
                    label: 'Billing invoice',
                    selected: path === '/payment',
                    onClick: () => changeRoute('payment', false, true),
                  }
                ]
                :
                []
            },
            {
              // url: '#',
              label: 'Account',
              icon: path === '/profile' ? PersonIcon : PersonFilledIcon,
              selected: path === '/profile',
              onClick: () => changeRoute('profile')
            },
            localStorage.getItem('isbusr') === 'false' && localStorage.getItem('istm') !== 'true' &&
            {
              // url: '#',
              label: 'Refer & earn',
              icon: path === '/refer' ? CashDollarIcon : CashDollarFilledIcon,
              selected: path === '/refer',
              onClick: () => changeRoute('refer'),
              excludePaths: ["#"],

            },
          ]}
        />

        <Navigation.Section
          items={[
            {
              url: '#',
              label: 'Switch to old view',
              icon: ExternalIcon,
              selected: false,
              onClick: () => clickSendOutside('Social Wall', `https://legacy.wisernotify.com?q=${window.localStorage.getItem('authToken')}&un=${window.localStorage.getItem('e')}&pvd=${window.localStorage.getItem('pvd')}&utyp=${window.localStorage.getItem('utyp') || 'local'}`)
            },
            props.profileData?.addonqty > 0 &&
            {
              url: '#',
              label: 'Social wall',
              icon: ExternalIcon,
              selected: path === '/social-wall',
              onClick: () => clickSendOutside('Social Wall', 'https://app.wisernotify.com/widget/wdlist?ds=type')
            },
            localStorage.getItem('isbusr') === 'false' && localStorage.getItem('istm') !== 'true' &&
            {
              url: '#',
              label: 'Feature request',
              icon: SunIcon,
              selected: path === '/feature-request',
              onClick: () => clickSendOutside('Feature Request', `https://roadmap.wisernotify.com/sso/auth?payload=${props.profileData?.feedbearToken}`)
            },
            localStorage.getItem('isbusr') === 'false' && localStorage.getItem('istm') !== 'true' &&
            {
              url: '#',
              label: 'Support docs',
              icon: QuestionCircleIcon,
              selected: path === '/knowledge',
              onClick: () => clickSendOutside('Knowledge', 'https://support.wisernotify.com/')
            }
          ]} />
      </Navigation>
    </div>
  );
}

export default Sidebar;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const pricingPlan = createSlice({
  name: 'pricingPlan',
  initialState,
  reducers: {
    getPlan: (state, action) => { },
    getPlanSuccess: (state, action) => {
      return { ...state, lstPlan: action.payload };
    },
    getWixPricingPlan: (state, action) => { },
    getWixPricingPlanSuccess: (state, action) => {
      return { ...state, wixPricingPlan: action.payload };
    },
    getAddon: (state, action) => { },
    getAddonSuccess: (state, action) => {
      return { ...state, lstAddon: action.payload };
    },
    createChargeBeeForm: (state, action) => { },
    createChargeBeeFormSuccess: (state, action) => {
      return { ...state, chargebeeFrm: action.payload };
    },
    createChargeBeeFormClear: (state, action) => {
      return { ...state, chargebeeFrm: {} };
    },
    chargbeePayment: (state, action) => { },
    chargbeePaymantSuccess: (state, action) => {
      return { ...state, chargbeeSuccess: action.payload };
    },
    createChargeBeeAddonForm: (state, action) => { },
    createChargeBeeAddonFormSuccess: (state, action) => {
      return { ...state, chargebeeAddonFrm: action.payload };
    },
    createChargeBeeAddonFormClear: (state, action) => {
      return { ...state, chargebeeAddonFrm: {} };
    },
    chargbeeAddonPayment: (state, action) => { },
    chargbeeAddonPaymentSuccess: (state, action) => {
      return { ...state, chargbeeAddonSuccess: action.payload };
    },
    cancelSubscription: (state, action) => { },

    createStripeLink: (state, action) => { },
    createStripeLinkSuccess: (state, action) => {
      return { ...state, stripeData: action.payload }
    },
    activeTrialPlan: (state, action) => { },
  }
});

export const {
  getPlan,
  getPlanSuccess,
  getAddon,
  getAddonSuccess,
  createChargeBeeForm,
  createChargeBeeFormSuccess,
  createChargeBeeFormClear,
  chargbeePayment,
  chargbeePaymantSuccess,
  createChargeBeeAddonForm,
  createChargeBeeAddonFormSuccess,
  createChargeBeeAddonFormClear,
  chargbeeAddonPayment,
  chargbeeAddonPaymentSuccess,
  cancelSubscription,
  createStripeLink,
  createStripeLinkSuccess,
  activeTrialPlan,
} = pricingPlan.actions;

export default pricingPlan.reducer;

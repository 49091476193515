import { Badge, BlockStack, Box, Button, Card, Divider, InlineStack, Layout, Page, Text, ButtonGroup, InlineGrid, Select, TextField, SkeletonDisplayText, SkeletonBodyText, Tooltip, Modal } from '@shopify/polaris';
import React from 'react';
import { checkCircle_icon, planTrue_icon } from '../../img';
import BillingSummery from '../PricingPlan/BillingSummery';
import { useSelector } from 'react-redux';
// import { BillingInvoice } from '../../containers';
import BillingInvoice from '../../containers/BillingInvoice/BillingInvoice';

function ShopifyPricingPlanCard(props) {
  const { state, changeNameValue } = props;
  const isLoading = useSelector(state => state.loading.isLoading);

  const planDescList = [
    { icon: checkCircle_icon, label: '14 Day money back guarantee' },
    { icon: checkCircle_icon, label: 'Change or cancel the plan any time' }
  ]

  const tabs = [
    { tabName: 'month', label: 'Pay monthly' },
    { tabName: 'year', label: 'Pay yearly (2 Month Free)' },
  ];

  const proIncludeList = [
    { icon: planTrue_icon, label: 'Premium Templates', tooltip: 'Access all notification templates designs' },
    { icon: planTrue_icon, label: 'Unlimited Notifications', tooltip: 'Create unlimited notifications and widgets without extra charges. Show them as many times as you want, up to the monthly visitor limit in your plan.' },
    { icon: planTrue_icon, label: 'Branding control', tooltip: 'Display a ‘Verified by WiserNotify’ badge to assure visitors that your notifications are trusted and genuine. If you want to change, hide, or customize the badge, you can—it’s completely up to you.' },
    {
      icon: planTrue_icon, label: 'A/B testing & Goal Tracking', tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">Test notification text, colors, and CTAs with split testing to find the best variation.</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">Measure WiserNotify’s impact on your website's goals.</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: 'Custom CSS', tooltip: 'Allowing you to add your custom CSS to change the notification design.' }
  ]

  const growthIncludeList = [
    { icon: planTrue_icon, label: 'In-line social proof messaging', tooltip: 'Display trust-building messages directly within your website content.' },
    { icon: planTrue_icon, label: 'Dedicated Account Manager', tooltip: 'Get personalized guidance from an account manager.' },
    { icon: planTrue_icon, label: 'Custom Design & Technical support', tooltip: 'Need a unique design or changes to your notifications? Our team handles custom design, implementation, and consulting to fit your needs.' }
  ]

  const basicIncludeList = [
    { icon: planTrue_icon, label: 'Limited templates', tooltip: 'Access a selection of basic notification template designs to get started.' },
    { icon: planTrue_icon, label: '1 active notification', tooltip: 'Activate and display one notification on your website at a time.' },
    {
      icon: planTrue_icon, label: 'Social Proof & Urgency Widgets', tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">Show recent sales, sales counters, and sign-ups with counters.</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>Promote announcements, offers, and countdown timers for urgency.</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>Highlight reviews, review counts, visitor counters, and social media followers.</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>Include animated blinking tabs, WhatsApp CTAs, and more.</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>Customize notifications with advanced behavioral rules for maximum impact.</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: 'Multiple websites', tooltip: 'Use the plan across multiple domains without additional charges.' },
    { icon: planTrue_icon, label: '200 + Integrations', tooltip: 'Seamlessly connect with over 200 tools and platforms.' },
    { icon: planTrue_icon, label: 'Live Chat Support', tooltip: 'Get quick assistance through real-time chat support.' },
    {
      icon: planTrue_icon, label: 'Reporting & Settings', tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">Analyze performance with detailed reports on hovers, clicks, and engagement.</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">Adjust notification orders, loops, timings, data, and close settings with advanced control.</Text>
          </div>
        </BlockStack>
    }
  ]

  return (
    <div className='page-wrap page-plan-wrap'>
      <Box paddingBlockEnd={500}>
        <Page title={'Plan'}>
          <Card>
            <BlockStack gap={300}>
              <Text variant='bodyLg' as='h6' fontWeight='semibold'>Select a plan based on your monthly visitor traffic</Text>
              <Box>
                <InlineStack gap={200} wrap={false} blockAlign='center'>
                  {planDescList && planDescList.map((data, index) => (
                    <InlineStack key={index} gap={100} blockAlign='center'>
                      <img src={data.icon} alt='' />
                      <Text as='p' fontWeight='regular'>{data.label}</Text>
                    </InlineStack>
                  ))}
                </InlineStack>
              </Box>
            </BlockStack>

            <div id='plan_block'>
              <Box paddingBlockEnd={500}>
                <Layout>
                  <Layout.Section variant='oneThird'>
                    <Box id='shp_pln_basic_block' padding={400} borderRadius={300}>
                      <BlockStack gap={300}>
                        <Box>
                          <Badge tone="warning">Free Plan</Badge>
                        </Box>
                        <Text variant='bodySm' as='p' fontWeight='regular'>Best for who start building funnel & growing the traffic</Text>
                        <Divider borderWidth='050' />
                        {isLoading ?
                          <Box>
                            <Box width='30%' paddingBlockStart={300}>
                              <SkeletonBodyText lines={1} />
                            </Box>
                            <Box paddingBlock={400}>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </Box>
                          </Box> :
                          <>
                            <Box>
                              <Text variant='headingLg' as='h3' fontWeight='semibold'>$0</Text>
                            </Box>
                            <TextField
                              value='1,000 Visitors/month'
                              autoComplete="off"
                              disabled
                            />
                          </>}
                        <Box paddingBlockStart={400}>
                          <Button id='primary_btn' fullWidth disabled>
                            <Text variant='bodyMd' fontWeight='semibold'>Free plan {state.profile && state.profile.csub === 'free' ? 'activated' : ''}</Text>
                          </Button>
                        </Box>
                        <Box>
                          <BlockStack gap={100}>
                            <Text variant='bodyMd' fontWeight='semibold'>
                              Includes
                            </Text>
                            {basicIncludeList && basicIncludeList.map((data, index) => (
                              <InlineStack key={index} gap={100} blockAlign='center'>
                                <img src={data.icon} alt='' />
                                <Tooltip dismissOnMouseOut padding='400' content={data.tooltip} hasUnderline width='wide'>
                                  <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                </Tooltip>
                              </InlineStack>
                            ))}
                          </BlockStack>
                        </Box>
                      </BlockStack>
                    </Box>
                  </Layout.Section>
                  <Layout.Section>
                    <Box background='bg-surface-tertiary' id='plan_pg_block' borderRadius={300} paddingInline={800} paddingBlockEnd={500} paddingBlockStart={500}>
                      <InlineStack blockAlign='center' align='center'>
                        <Box background='bg-surface' borderRadius='300' padding={100} borderColor="border" borderWidth="025">
                          <ButtonGroup>
                            {tabs.map((data, index) => (
                              <Button key={index} variant={state.plv === data.tabName ? 'primary' : ''}
                                onClick={() => props.handleTabChange(data)}>
                                <Text variant='bodyLg' as='p' fontWeight='regular'>{data.label}</Text>
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Box>
                      </InlineStack>
                      <Box paddingBlockStart={400}>
                        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400}>
                          <Box background='bg-surface' padding={400} borderRadius='300' minHeight='429.99px'>
                            <BlockStack gap={300}>
                              <Box>
                                <Badge tone="info">Pro</Badge>
                              </Box>
                              <Text variant='bodySm' as='p' fontWeight='regular'>Ideal for D2C brands running marketing campaigns and generating steady traffic</Text>
                              <Divider />

                              {isLoading ?
                                <Box>
                                  <Box width='30%' paddingBlockStart={300}>
                                    <SkeletonBodyText lines={1} />
                                  </Box>
                                  <Box paddingBlock={400}>
                                    <SkeletonDisplayText size='medium' maxWidth='100%' />
                                  </Box>
                                </Box>
                                :
                                <>< Box >
                                  <InlineStack gap={100} blockAlign='center'>
                                    <Text variant='headingLg' as='p' fontWeight='semibold'>${state.selctdProPlanPrice ? state.selctdProPlanPrice : '-'}</Text>
                                    <Text variant='headingXs' as='p' fontWeight='regular'>{`per ${state.plv}`}</Text>
                                    {state?.selctdPlanId && state.proPlanList.find(plan => plan.uaq === state.selctdProPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) && (
                                      <Badge tone="success">Selected</Badge>
                                    )}
                                  </InlineStack>
                                </Box>

                                  <Box minHeight='50px'>
                                    <Select
                                      options={state.proOptionList}
                                      onChange={(e) => changeNameValue({ selctdProPlanVisitor: parseInt(e) })}
                                      value={state.selctdProPlanVisitor}
                                    />
                                  </Box>
                                </>}

                              {state?.selctdPlanId && state.proPlanList.find(plan => plan.uaq === state.selctdProPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) ?
                                <Button id='primary_btn' fullWidth disabled>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>Current Active Plan</Text>
                                </Button>
                                :
                                <Button id='primary_btn' fullWidth onClick={() => props.handleShopifyPayment(state.selctdProPlanPrice)} disabled={state.selctdProPlanPrice <= state.selctdPlanPrice ? true : false}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                    {(state.profile && state.profile.billing && state.profile.billing.icnc !== true) ?
                                      `Upgrade ${state.plv === 'year' ? 'yearly' : 'monthly'}` : `Get Started Now`
                                    }
                                  </Text>
                                </Button>
                              }

                              <Box>
                                <Box paddingBlockEnd={100}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>Everything includes in Basic +</Text>
                                </Box>
                                <BlockStack gap={100}>

                                  {proIncludeList && proIncludeList.map((data, index) => (
                                    <InlineStack key={index} gap={100} blockAlign='center'>
                                      <img src={data.icon} alt='' />
                                      <Tooltip dismissOnMouseOut padding='400' content={data.tooltip} hasUnderline width='wide'>
                                        <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                      </Tooltip>
                                    </InlineStack>
                                  ))}

                                </BlockStack>
                              </Box>
                            </BlockStack>
                          </Box>

                          <Box background='bg-surface' padding={400} borderRadius='300'>
                            <BlockStack gap={300}>
                              <Box>
                                <Badge tone="attention">Growth</Badge>
                              </Box>
                              <Text variant='bodySm' as='p' fontWeight='regular'>Best for high traffic site & building marketing funnel with custom pages</Text>
                              <Divider />
                              {isLoading ?
                                <Box>
                                  <Box width='30%' paddingBlockStart={300}>
                                    <SkeletonBodyText lines={1} />
                                  </Box>
                                  <Box paddingBlock={400}>
                                    <SkeletonDisplayText size='medium' maxWidth='100%' />
                                  </Box>
                                </Box>
                                : <>
                                  <Box>
                                    <InlineStack gap={100} blockAlign='center'>
                                      <Text variant='headingLg' as='h3'>
                                        ${state.selctdGrowthPlanPrice ? state.selctdGrowthPlanPrice : '-'}
                                      </Text>
                                      <Text variant='bodySm' as='p' >{`per ${state.plv}`}</Text>

                                      {state?.selctdPlanId && state.growthPlanList.find(plan => plan.uaq === state.selctdGrowthPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) && (
                                        <Badge tone="success">Selected</Badge>
                                      )}

                                    </InlineStack>
                                  </Box>
                                  <Box minHeight='50px'>
                                    <Select
                                      options={state.growthOptionList}
                                      onChange={(e) => changeNameValue({ selctdGrowthPlanVisitor: parseInt(e) })}
                                      value={state.selctdGrowthPlanVisitor}
                                    />
                                  </Box>
                                </>}

                              {state?.selctdPlanId && state.growthPlanList.find(plan => plan.uaq === state.selctdGrowthPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) ?
                                <Button id='primary_btn' fullWidth disabled>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>Current Active Plan</Text>
                                </Button>
                                :
                                <Button id='primary_btn' fullWidth onClick={() => props.handleShopifyPayment(state.selctdGrowthPlanPrice)} disabled={state.selctdGrowthPlanPrice <= state.selctdPlanPrice ? true : false}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold' >
                                    {(state.profile && state.profile.billing && state.profile.billing.icnc !== true) ?
                                      `Upgrade ${state.plv === 'year' ? 'yearly' : 'monthly'}` : `Get Started Now`
                                    }
                                  </Text>
                                </Button>
                              }

                              <Box>
                                <Box paddingBlockEnd={100}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                    Everything includes in Pro +
                                  </Text>
                                </Box>
                                <BlockStack gap={100}>
                                  {growthIncludeList && growthIncludeList.map((data, index) => (
                                    <InlineStack key={index} gap={100} blockAlign='center'>
                                      <img src={data.icon} alt='' />
                                      <Tooltip dismissOnMouseOut padding='400' content={data.tooltip} hasUnderline width='wide'>
                                        <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                      </Tooltip>
                                    </InlineStack>
                                  ))}
                                </BlockStack>
                              </Box>
                            </BlockStack>
                          </Box>
                        </InlineGrid>
                      </Box>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Box>
              <Card>
                <InlineStack align='space-between' blockAlign='center'>
                  <BlockStack gap={100}>
                    <Text variant='bodySm' as='h6' fontWeight='semibold'>Have more than 1 million visitors?</Text>
                    <Text tone="subdued" variant='bodySm' as='p' fontWeight='regular'>Tell us your monthly visitors traffic & requirements. </Text>
                  </BlockStack>
                  <Button>Chat with us</Button>
                </InlineStack>
              </Card>
            </div>

          </Card >
          {
            state.profile && state.profile.billing && state.profile.billing.icnc === false &&
            <Box paddingBlock={700}>
              <Box paddingBlockEnd={'200'}>
                <InlineStack align='space-between'>
                  <Text as='p' variant='bodyLg' fontWeight='semibold'>Active Plan</Text>
                  <Button variant="plain" onClick={() => changeNameValue({ billingInvoiceOpen: true })}>Billing history</Button>
                </InlineStack>
              </Box>
              <BillingSummery props={props} />
            </Box>
          }
        </Page >
      </Box >

      {/* open billing Invoice modal */}
      <Modal
        size='large'
        open={state.billingInvoiceOpen}
        onClose={() => changeNameValue({ billingInvoiceOpen: false })}
      >
        <div className='full-screen-open'>
          <Box minHeight='90vh' id='add_inte_wrap' paddingInlineEnd={300}>
            <BillingInvoice />
          </Box>
        </div>
      </Modal>
    </div >

  );
}

export default ShopifyPricingPlanCard;

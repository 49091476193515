import React from 'react';
import './ErrorBoundaryUI.css';

function ErrorBoundaryUI(props) {
  const handleLogout = () => {
    localStorage.removeItem('restoreId');
    localStorage.removeItem('authToken');
    localStorage.removeItem('username');
    localStorage.removeItem('e');
    localStorage.removeItem('ti');
    localStorage.removeItem('pvd');
    localStorage.removeItem('csub');
    localStorage.removeItem('istm');
    localStorage.removeItem('role');
    localStorage.removeItem('isbusr');//white_label
    localStorage.removeItem('utyp');
    localStorage.removeItem('isAppsumo');
    localStorage.removeItem('alg');
    localStorage.removeItem('fvicn');
    localStorage.removeItem('isMaster');
    localStorage.removeItem('isPixelIns');
    localStorage.removeItem('is_hide_blackfriday');
    localStorage.removeItem('loginTime');
    localStorage.removeItem('UTM_SIGNUP');
    localStorage.removeItem('rdt_url');
    localStorage.removeItem('iskponbrdng');
    localStorage.removeItem('iscmpltdonbrdng');
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }

  return (
    <div className="wraperr-box">
      <div className="err-box">
        <h2 className="h2-title-ohno">Internal Server Error</h2>
        <div className="ohno-subtitle">
          <p>We apologize for the inconvenience.</p>
          <p>An unexpected error occurred while processing your request.</p>
          <p>Our team has been notified, and we are actively working to resolve this issue. Please try again later.</p>
          {
            localStorage.getItem('isbusr') !== 'true' &&
            <p>If the problem persists, feel free to contact our support team at
              <a href="mailto:support@wisernotify.com">support@wisernotify.com</a>
              for further assistance.</p>
          }
          <p>Thank you for your understanding.</p>
        </div>
        <br />
        {
          localStorage.getItem('isbusr') !== 'true' &&
          <button className="home-button" onClick={handleLogout}>Go to Home</button>
        }
      </div>
    </div>
  );
}

export default ErrorBoundaryUI;
import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import * as apiNotif from '../services/apiNotif';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/notif';
import { toastify } from '../ducks/toast';

function* getNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getNotifType', action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotifSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notofication data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotiHght(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getNotifHeight', action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotiHghtSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotifTypeList(action) {
  try {
    // yield put(load());
    var res;
    if (action.payload.domain !== undefined) {
      res = yield call(api.GET, `/getFilterOption?typ=${action.payload.typ}&domain=${action.payload.domain}`);
    }
    else {
      res = yield call(api.GET, `/getFilterOption?typ=${action.payload.typ}`);
    }

    if (res.status === 'success') {
      yield put(actions.getNotifTypeListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to filter option data' }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotiByUser(action) {
  try {
    yield put(load());
    let path = '/getNotifByUser';

    //check if login user is team member pass a flag
    if (localStorage.getItem('istm') === 'true') {
      path = '/getNotifByUser?istm=' + true;
    }
    const res = yield call(api.POST, path, action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotiByUserSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification by user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotifActivityByUser(action) {
  try {
    yield put(load());
    let path = '/getNotifActivityByUser';

    //check if login user is team member pass a flag
    if (localStorage.getItem('istm') === 'true') {
      path = '/getNotifActivityByUser?istm=' + true;
    }
    const res = yield call(api.POST, path, action.payload);

    if (res.status === 'success') {
      yield put(actions.getNotifActivityByUserSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification by user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUserNotifData(action) {
  try {
    // yield put(load());

    const res = yield call(api.POST, '/getUserNotifData', action.payload);
    if (res.status === 'success') {
      yield put(actions.getUserNotifDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get notification by user' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updNotifStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `/updNotifStatus`, action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'limit') {
      if (action.payload.togl && action.payload.togl === true) {
        let obj = {
          _id: action.payload._id,
          dt: new Date().getTime()
        };
        yield put(actions.updNotifStatusSuccess(obj));
      }
      else {
        yield put(toastify({ type: 'error', msg: res.m }));
      }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification status' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createNotif', action.payload);
    if (res.status === 'success') {

      if (action.payload.typ && action.payload.typ === 'inlineNudge') {
        action.payload.navigate(`/notifEdit?nti=${res.data.nti}&id=${res.data._id}&ntt=${res.data.ntt}&ntyp=inlinenudge`);
      }
      else if (action.payload.typ && action.payload.typ === 'widget') {
        action.payload.navigate(`/notifEdit?nti=${res.data.nti}&id=${res.data._id}&ntt=${res.data.ntt}&ntyp=widget`);
      }
      else {
        action.payload.navigate(`/notifEdit?nti=${res.data.nti}&id=${res.data._id}&ntt=${res.data.ntt}`);
      }

      yield put(actions.createNotifSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotiByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));

    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to create notification' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/delNotification?id=' + action.payload.id + '&fn=' + action.payload.fn);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotiByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete notification' }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createInlineNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createInlineNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotiByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteInlineNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteInlineNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotiByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCloneNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/cloneNotifi', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to create clone notification' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getNotifPixel() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getDomainListForNotif');
    if (res.status === 'success') {
      yield put(actions.getNotifPixelSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get pixel data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* smartDebugger(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `/smartDebuger?nti=${action.payload}`);
    if (res.status === 'success') {
      yield put(actions.smartDebuggerSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification name' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updMerge(action) {
  try {
    yield call(apiNotif.POST, '/updMerge', action.payload);   //http://localhost:1015/cron/uniq?userID=5dcfba8e9658d004b4e877c0
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Failed to call api' }));
  }
}

function* updNotifName(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updNotifName', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update notification name' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAbTestNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createAbTestNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.abTestNotifSuccess(res.data));
      yield put(actions.getNotiByUser(global.filterUserNotif));
      yield put(actions.getNotifActivityByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updAbTestNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updAbTestNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to update record' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* setOriginalNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/setOriginalNotif', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to set original notification' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAbTestNotif(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/deleteAbTestNotif?notifId=' + action.payload.notifId + '&abId=' + action.payload.abId);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getNotiByUser(global.filterUserNotif));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to delete data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getAbAnalyticEs(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getAbAnalyticEs?ti=' + action.payload.ti +
      '&nid=' + action.payload.nid +
      '&fDate=' + action.payload.fDate +
      '&tDate=' + action.payload.tDate +
      '&rt=' + action.payload.rt);
    if (res.status === 'success') {
      yield put(actions.getAbAnalyticEsSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get A/b testing analytic' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getRecentCapturedData(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getRecentCapturedData?nid=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.getRecentCapturedDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get recent captured data' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getActiveIntgList() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getActiveIntgList');
    if (res.status === 'success') {
      yield put(actions.getActiveIntgListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get active integration list' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createDummyData(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createDummyData', action.payload);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: 'Data Created successfully' }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetNotif() {
  yield takeLatest(actions.getNotif.type, getNotif);
}

export function* watchGetNotiHght() {
  yield takeLatest(actions.getNotiHght.type, getNotiHght);
}

export function* watchGetNotifTypeList() {
  yield takeLatest(actions.getNotifTypeList.type, getNotifTypeList);
}

export function* watchGetNotiByUser() {
  yield takeLatest(actions.getNotiByUser.type, getNotiByUser);
}

export function* watchGetNotifActivityByUser() {
  yield takeLatest(actions.getNotifActivityByUser.type, getNotifActivityByUser);
}

export function* watchGetUserNotifData() {
  yield takeLatest(actions.getUserNotifData.type, getUserNotifData);
}

export function* watchUpdNotifStatus() {
  yield takeLatest(actions.updNotifStatus.type, updNotifStatus)
}

export function* watchCreateNotif() {
  yield takeLatest(actions.createNotif.type, createNotif);
}

export function* watchDelNotif() {
  yield takeLatest(actions.delNotif.type, delNotif);
}

export function* watchCreateInlineNotif() {
  yield takeLatest(actions.createInlineNotif.type, createInlineNotif);
}

export function* watchDeleteInlineNotif() {
  yield takeLatest(actions.deleteInlineNotif.type, deleteInlineNotif);
}

export function* watchCreateCloneNotif() {
  yield takeLatest(actions.createCloneNotif.type, createCloneNotif);
}

export function* watchGetNotifPixel() {
  yield takeLatest(actions.getNotifPixel.type, getNotifPixel);
}

export function* watchSmartDebugger() {
  yield takeLatest(actions.smartDebugger.type, smartDebugger);
}

export function* watchUpdMerge() {
  yield takeLatest(actions.updMerge.type, updMerge);
}

export function* watchUpdNotifName() {
  yield takeLatest(actions.updNotifName.type, updNotifName);
}

export function* watchCreateAbTestNotif() {
  yield takeLatest(actions.createAbTestNotif.type, createAbTestNotif);
}

export function* watchUpdAbTestNotif() {
  yield takeLatest(actions.updAbTestNotif.type, updAbTestNotif);
}

export function* watchSetOriginalNotif() {
  yield takeLatest(actions.setOriginalNotif.type, setOriginalNotif);
}

export function* watchDeleteAbTestNotif() {
  yield takeLatest(actions.deleteAbTestNotif.type, deleteAbTestNotif);
}

export function* watchGetAbAnalyticEs() {
  yield takeLatest(actions.getAbAnalyticEs.type, getAbAnalyticEs);
}

export function* watchGetRecentCapturedData() {
  yield takeLatest(actions.getRecentCapturedData.type, getRecentCapturedData);
}

export function* watchGetActiveIntgList() {
  yield takeLatest(actions.getActiveIntgList.type, getActiveIntgList);
}

export function* watchCreateDummyData() {
  yield takeLatest(actions.createDummyData.type, createDummyData);
}

export default function* rootSaga() {
  yield fork(watchGetNotif);
  yield fork(watchGetNotiHght);
  yield fork(watchGetNotifTypeList);
  yield fork(watchGetNotiByUser);
  yield fork(watchGetNotifActivityByUser);
  yield fork(watchGetUserNotifData);
  yield fork(watchUpdNotifStatus);
  yield fork(watchCreateNotif);
  yield fork(watchDelNotif);
  yield fork(watchCreateInlineNotif);
  yield fork(watchDeleteInlineNotif);
  yield fork(watchCreateCloneNotif);
  yield fork(watchGetNotifPixel);
  yield fork(watchSmartDebugger);
  yield fork(watchUpdMerge);
  yield fork(watchUpdNotifName);
  yield fork(watchCreateAbTestNotif);
  yield fork(watchUpdAbTestNotif);
  yield fork(watchSetOriginalNotif);
  yield fork(watchDeleteAbTestNotif);
  yield fork(watchGetAbAnalyticEs);
  yield fork(watchGetRecentCapturedData);
  yield fork(watchGetActiveIntgList);
  yield fork(watchCreateDummyData);
}

import React, { useEffect, useState } from 'react';
import { Banner, Box, Button, InlineStack, Modal, Text } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as billingDucks from '../../ducks/billingInvoice';
import ShopifyPricingPlan from '../../containers/ShopifyPricingPlan/ShopifyPricingPlan';
import WixPricingPlan from '../../containers/WixPricingPlan/WixPricingPlan';
import PricingPlan from '../../containers/PricingPlan/PricingPlan';
import { useDispatch } from 'react-redux';

function BiilingWarning(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    mailTo: '',
    planUse: 0,
    isOpenChargebeePymFrm: false,
    isOpenBanner: true
  });

  const changeNameValue = (obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }

  const profile = useSelector((state) => state?.auth?.profile);
  const chargebeePay = useSelector(state => state.billingInvoice?.mangePaymentSrc);

  useEffect(() => {
    changeNameValue({ mailTo: profile?.brand?.feadr });
    let userQuota = profile?.uuq;
    if (profile?.puuq) {
      userQuota = userQuota + profile.puuq;
    }
    let planUse = userQuota / profile?.uaq * 100;
    changeNameValue({ planUse: parseInt(planUse) });
  }, [profile])

  const getPlanNew = (typ) => {
    // if (typ === 'cancel') {
    //   navigate('/plan')
    // }
    // else {
    //   navigate('/plan?plan=' + profile.billing.pobj.id)
    // }
    changeNameValue({ planPageOpen: true })
    if (typ === 'cancel') {
      if (window._cio) {
        window._cio.track('Red_Alert_Subscribe_Your_Plan');
      }
    }
    else {
      if (window._cio) {
        window._cio.track('Red_Alert_Retry_Now');
      }
    }
  };

  const paymantRetry = () => {
    let obj = {
      cust_id: profile.cbe.client_id
    };
    dispatch(billingDucks.paymantRetry(obj));

    if (window._cio) {
      window._cio.track('Red_Alert_Retry_Payment');
    }
  }

  //manage payment source
  const mangePaymentSrc = () => {
    dispatch(billingDucks.mangePaymentSrc());
    changeNameValue({ isOpenChargebeePymFrm: true })

    if (window._cio) {
      window._cio.track('Red_Alert_Change_Payment_Details');
    }
  }

  const getButtonClick = (typ) => {
    if (typ === 'upgrade') {
      if (window._cio) {
        window._cio.track('Red_Alert_Upgrade_Now');
      }
    }
    else {
      if (window._cio) {
        window._cio.track('Red_Alert_Upgrade_Basic');
      }
    }
  };

  useEffect(() => {
    if (chargebeePay && state.isOpenChargebeePymFrm === true) {
      if (chargebeePay) {
        var chargebeeInstance = window.Chargebee.getInstance();
        chargebeeInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeePay.hosted_page;
              resolve(sampleResponse);
            });
          },
        });
        changeNameValue({ isOpenChargebeePymFrm: false });
      }
    }
  }, [chargebeePay])

  //handle Banner Close Btn
  const handleDismissBtn = () => {
    changeNameValue({ isOpenBanner: false })
    dispatch(billingDucks.rmvUserAlrt());
  }

  //handleGetStaredBtn
  const handleGetStaredBtn = () => {
    if (window.location.pathname !== '/plan') {
      changeNameValue({ planPageOpen: true })
    }

    if (window._cio) {
      window._cio.track('get_stared_btn_14_days');
    }
  }

  return (
    state.isOpenBanner &&
    <div>
      {
        //user is not from shopify, wix, shopline, appsumo and user is not activated plan
        //than show banner to activate plan to display notifications
        (profile && profile?.csub === undefined && profile?.blid === undefined && profile?.pvd !== 'shopify' && profile?.pvd !== 'wix' && profile?.pvd !== 'shopline' && profile?.utyp !== 'appsumo') ?
          <Banner
            title={'Activate free or paid plan to display notifications'}
            tone="critical"
          >
            Unlock full access with a 14-day money-back guarantee.<span style={{ padding: '0px 5px' }}><Button variant="plain" onClick={() => handleGetStaredBtn()}>Get started</Button></span>
          </Banner>
          :
          //user is from appsumo and user is not activated plan
          //than show banner to activate plan to display notifications (appsumo addon plan)
          profile && profile.billing && profile.cbtrnflmsg && profile.cbtrnflmsg !== undefined && profile.utyp === 'appsumo' && profile.billing.icnc === true && profile.billing.pobj.id === "appsumo-spacial" ?
            <Banner
              title='Your social-wall plan is inactive due to a payment failure'
              tone="critical"
              onDismiss={() => handleDismissBtn()}
            >
              <InlineStack gap={300}>
                <Button onClick={() => paymantRetry()}>Retry now</Button>
              </InlineStack>
            </Banner>
            :
            //if user is not in free plan or not not expired
            profile && profile.isfrexp !== true ?
              //user is from chargebee and paymant failed than show banner to retry payment and message used is from chargebee
              profile.cbtrnflmsg && profile.cbtrnflmsg !== undefined ?
                <Banner
                  title={profile.cbtrnflmsg}
                  tone="critical"
                >
                  <InlineStack gap={300}>
                    {
                      profile.billing && profile.billing.icnc === true ?
                        <Button onClick={() => getPlanNew()}>Retry now</Button> :
                        <Button onClick={() => paymantRetry()}>Retry now</Button>
                    }
                    {
                      profile.cbtrnflmsg === 'Your card was declined.' &&
                      <Button onClick={() => mangePaymentSrc()}>Change payment details</Button>
                    }
                  </InlineStack>
                </Banner>
                :
                //user is from shopify and user is not activated plan
                (profile && profile.pvd === 'shopify' && profile.billing && profile.billing.icnc === true) ?
                  <Banner
                    title={'You have canceled your plan'}
                    tone="critical"
                  >
                    <Button onClick={() => getPlanNew('cancel')}>Subscribe Your Plan</Button>
                  </Banner>
                  :
                  //user is from wix and user is not activated plan
                  (profile && profile.pvd === 'wix' && profile.billing && profile.billing.icnc === true) ?
                    <Banner
                      title={'You have canceled your plan'}
                      tone="critical"
                    >
                      <Button onClick={() => getPlanNew('cancel')}>Subscribe Your Plan</Button>
                    </Banner>
                    :
                    //user is not from appsumo and user is not activated plan
                    (profile && profile.utyp !== 'appsumo' && profile.billing && profile.billing.iexp === true && profile.billing.icnc === true) ?
                      <Banner
                        title={'You have canceled your plan'}
                        tone="critical"
                      >
                        <Button onClick={() => getPlanNew('cancel')}>Subscribe Your Plan</Button>
                      </Banner>
                      :
                      // user and subuser used all quota display him upgrade now
                      (state.planUse === 100 || state.planUse > 100) && <>
                        {
                          localStorage.getItem('isbusr') === 'true' ?
                            <>
                              {
                                //condition for subuser plan is expired
                                new Date() > new Date(profile?.billing?.pendt) ?
                                  <Banner
                                    title={'Your plan has expired'}
                                    tone="critical"
                                  >
                                    <Text> Your plan has expired. Please contact us at <a style={{ padding: '0px' }} href={state.mailTo}>{profile?.brand?.feadr}</a> for renewal</Text>
                                  </Banner>
                                  :
                                  //subuser used all quota display him agency email 
                                  <Banner
                                    title={"You've consumed 100% visitor quota in your current plan."}
                                    tone="critical"
                                  >
                                    <div style={{ display: 'flex' }}>
                                      <p> To continue displaying notifications,</p>
                                      <Box paddingInlineStart={100}>
                                        <Text>
                                          <a href={state.mailTo}>{profile?.brand?.feadr}</a>
                                        </Text>
                                      </Box>
                                    </div>
                                  </Banner>
                              }
                            </>
                            :
                            //user used all quota display him upgrade now
                            <Banner
                              title={"You've consumed 100% visitor quota in your current plan."}
                              tone="critical"
                            >
                              To continue displaying notifications, <Link onClick={() => getButtonClick('upgrade')} className='alerts-btn' to='/plan'>Upgrade now.</Link>
                            </Banner>
                        }
                      </>
              :
              //if user is in free plan and free plan is expired display banner he have expired plan
              profile && profile.isfrexp === true &&
              <Banner
                title={'Your free plan has expired.'}
                tone="warning"
              >
                <Link onClick={() => getButtonClick('upgrade_basic')} className='alerts-btn' to='/plan' style={{ padding: '0px 5px' }}>Upgrade </Link> to continue displaying social proof, starting at $48/year.
              </Banner>
      }

      {/* open plan page modal */}
      <Modal
        size='large'
        open={state.planPageOpen}
        onClose={() => changeNameValue({ planPageOpen: false })}
      >
        <div className='full-screen-open'>
          <Box minHeight='90vh' id='add_inte_wrap' paddingInlineEnd={300}>
            {

              profile && profile.pvd === 'shopify' ?
                <ShopifyPricingPlan />
                :
                profile && profile.pvd === 'wix' ?
                  <WixPricingPlan />
                  :
                  profile &&
                  <PricingPlan />

            }
            {/* {profile.pvd === 'shopify' && <ShopifyPricingPlan />}
            {localStorage.getItem('pvd') === 'wix' && <WixPricingPlan />}
            {(localStorage.getItem('pvd') !== 'wix' && localStorage.getItem('pvd') !== 'shopify') && <PricingPlan />} */}
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default BiilingWarning;
